import { Validation } from '@lendoab/lendo-redux-form-helpers';
import ApplicantActions from 'APP/actions/ApplicantActions';
import Toast from 'APP/common/Toast';
import { Enum } from 'APP/enums/Applicant/CivilState';
import { Enum as CivilStateTypes } from 'APP/enums/Applicant/CivilState';
import { OccupationTexts } from 'APP/enums/Applicant/Occupation';
import { Enum as OccupationTypes, MortgageEmploymentTypes } from 'APP/enums/Applicant/Occupation';
import { Enum as ApplicantType } from 'APP/enums/Applicant/Type';
import { Enums as MortgageCivilStateTypes } from 'APP/enums/Application/Mortgage/CivilState';
import { upperCase } from 'APP/helpers/CommonHelper';
import ObjectHelper from 'APP/helpers/ObjectHelper';
import moment from 'moment';

const { ValidationFunctions } = Validation;

const guessCentury = (input) => {
    if (!input || input.length !== 10) {
        return '';
    }

    const shortYear = Number(input.substring(0, 2));
    const currentYear = new Date().getFullYear();
    const currentShortYear = parseInt(currentYear.toString().match(/\d{2}$/)[0], 10);

    return shortYear < currentShortYear + 5 ? '20' : '19';
};

const civilStateStrings = {
    [Enum.SINGLE]: 'Single',
    [Enum.LIVING_TOGETHER]: 'Living together',
    [Enum.MARRIED]: 'Married',
    [Enum.DIVORCED]: 'Divorced',
};

export default {
    getCivilStateString(civilState) {
        return civilStateStrings[civilState];
    },

    reformatSSNInput: (input) => {
        if (!input) {
            return input;
        }

        const value = input.replace(/\D/, '');

        if (!value || value.length !== 10) {
            return value;
        }

        // Now we assume that our number is a SSN
        // Guess the century and validate that
        const prefix = guessCentury(value);
        const newValue = `${prefix}${value}`;

        // If our new proposed value is not a SSN, do not modify
        if (!ValidationFunctions.swedishSSN(newValue)) {
            return value;
        }

        // Send out the modified value
        return `${prefix}${value}`;
    },

    mainApplicant: (applicants) => applicants.find((applicant) => applicant.type === ApplicantType.MAIN),
    coApplicant: (applicants) => applicants.find((applicant) => applicant.type === ApplicantType.CO),

    applicantType: (applicant) => `${upperCase(applicant.type)} applicant`,

    getEmploymentType: (applicant) => {
        return ObjectHelper.dotGet(applicant, 'employment.type');
    },

    employmentType: (type) => {
        const text = OccupationTexts[type];
        if (text) {
            return text;
        }
        return type;
    },

    fullName: (applicant) => {
        if (applicant.full_name) {
            return applicant.full_name;
        }

        if (applicant.user && applicant.user.personal_data) {
            return ObjectHelper.dotGet(applicant, 'user.personal_data.full_name');
        }

        return 'Unknown Name';
    },

    getMortgageFullName: (applicant) => {
        if (applicant?.full_name) {
            return applicant.full_name;
        }

        if (applicant?.personal_data) {
            return applicant.personal_data.full_name;
        }

        return '';
    },
    getApplicantFullName: (applicant) => {
        if (applicant?.full_name) {
            return applicant.full_name;
        }

        if (applicant?.user?.personal_data?.full_name) {
            return applicant.user.personal_data.full_name;
        }

        return '';
    },

    getGenderIcon(gender) {
        if (!gender) {
            return false;
        }
        if (gender === 'Male') {
            return 'man';
        } else if (gender === 'Female') {
            return 'woman';
        }
        return '';
    },

    getAppicantFullAddress(applicant) {
        if (!applicant.address) {
            return false;
        }

        const { street, postal_code, city, country } = applicant.address;

        return `${street}, ${postal_code} ${city} (${country})`;
    },

    calculateTimeLeft(endDate) {
        if (!endDate) {
            return undefined;
        }

        return moment.duration(endDate.diff(new Date()));
    },

    getApplicantSsn(applicant) {
        if (applicant) {
            return applicant.ssn;
        }
        return null;
    },

    isApplicantSelfEmployed(applicant) {
        if (applicant) {
            return applicant.employment.type === MortgageEmploymentTypes.SELF_EMPLOYMENT;
        }
        return false;
    },

    isApplicantMarried(applicant) {
        if (applicant) {
            return applicant.civil_state === MortgageCivilStateTypes.MARRIED;
        }
        return false;
    },

    isApplicantLivingTogether(applicant) {
        if (applicant) {
            return applicant.civil_state === MortgageCivilStateTypes.LIVE_TOGETHER;
        }
        return false;
    },

    hasCoApplicant(applicants) {
        if (applicants?.length > 0) {
            const coApplicant = applicants.filter((applicant) => applicant.type === ApplicantType.CO);
            if (coApplicant?.length === 1) {
                return true;
            }
            return false;
        }
        return false;
    },

    getCreditDataEstates(applicant) {
        if (applicant?.credit_data?.estates) {
            return applicant?.credit_data?.estates;
        }

        return [];
    },

    getMortgageEmploymentType(occupation) {
        switch (occupation) {
            case OccupationTypes.EARLY_PENSION:
                return MortgageEmploymentTypes.EARLY_RETIREMENT;
            case OccupationTypes.FINITE_TIME:
                return MortgageEmploymentTypes.TEMPORARY_EMPLOYMENT;
            case OccupationTypes.FULL_TIME:
                return MortgageEmploymentTypes.PERMANENT_EMPLOYMENT;
            case OccupationTypes.HOURLY_EMPLOYMENT:
                return MortgageEmploymentTypes.HOURLY_EMPLOYMENT;
            case OccupationTypes.OWN_COMPANY:
                return MortgageEmploymentTypes.SELF_EMPLOYMENT;
            case OccupationTypes.PART_TIME:
                return null;
            case OccupationTypes.STUDENT:
                return MortgageEmploymentTypes.STUDENT;
            case OccupationTypes.PENSION:
                return MortgageEmploymentTypes.RETIREMENT;
            case OccupationTypes.UNEMPLOYED:
                return MortgageEmploymentTypes.NO_EMPLOYMENT;
            default:
                return occupation;
        }
    },

    getMortgageCivilState(civilState) {
        switch (civilState) {
            case CivilStateTypes.DIVORCED:
                return null;
            default:
                return civilState;
        }
    },

    async clickToCall(phone) {
        try {
            const response = await ApplicantActions.clickToCall(phone, true);
            Toast('success', 'Click-to-call initiated to ' + phone);
            return response;
        } catch (error) {
            Toast('error', 'Failed to make click-to-call request. Error: ' + error);
        }
    },
};
