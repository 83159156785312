// Get search params  (insurance_id or ssn)
export function getInsuranceSearchParam(search) {
    const INSURANCE_ID_LENGTH = 36;
    const SWEDISH_SSN_LENGTH = 12;
    if (search.length === INSURANCE_ID_LENGTH) {
        return `insurance_id=${encodeURIComponent(search)}`;
    } else if (search.length === SWEDISH_SSN_LENGTH && /^\d{12}$/.test(search)) {
        return `ssn=${search}`;
    }
    return undefined;
}
