import React from 'react';
import { Message } from 'semantic-ui-react';

function AcceptedOfferWithdrawnMessage() {
    return (
        <Message warning>
            <Message.Header>
                You have withdrawn the offer the customer accepted. Please notify Lendo about the withdrawal.{' '}
            </Message.Header>
            <Message.Header style={{ marginTop: '5px' }}>
                If you wish to place a new offer, you can fill in the bid form below, but the user cannot choose the new
                offer until we are informed.
            </Message.Header>
            <Message.Header style={{ marginTop: '5px' }}>
                {' '}
                Tel: <a href="tel:0771-131310">0771 - 13 13 10</a>
            </Message.Header>
            <Message.Header style={{ marginTop: '5px' }}>
                {' '}
                E-mail: <a href="mailto:banker@lendo.se">banker@lendo.se</a>
            </Message.Header>
        </Message>
    );
}

export default AcceptedOfferWithdrawnMessage;
