export const Enums = {
    SINGLE: 'single',
    LIVE_TOGETHER: 'live_together',
    MARRIED: 'married',
};

export const Options = [
    { value: Enums.SINGLE, label: 'Single (Ensamstående)' },
    { value: Enums.LIVE_TOGETHER, label: 'Co-living (Sambo)' },
    { value: Enums.MARRIED, label: 'Married (Gift)' },
];
