import ApplicantActions from 'APP/actions/ApplicantActions';
import { createEffect } from 'APP/helpers/Effect';
import { useEffect, useReducer } from 'react';

const initialContactStateData = {
    status: 'fetching',
    effects: [],
    editing: false,
    applicantId: '',
    applicationType: '',
    email: '',
    phone: '',
    newEmail: '',
    newPhone: '',
    emailIncorrect: false,
    phoneIncorrect: false,
};

function reducer(state, event) {
    switch (state.status) {
        case 'fetching':
            if (event.type === 'UPDATE_CONTACT_DATA') {
                return {
                    ...state,
                    status: 'idle',
                    email: event.data.email,
                    phone: event.data.phone,
                    applicantId: event.data.applicantId,
                    applicationType: event.data.applicationType,
                    effects: [...state.effects],
                };
            }
            return state;
        case 'idle':
            if (event.type === 'SUBMIT') {
                return {
                    ...state,
                    newEmail: event.data.email,
                    newPhone: event.data.phone,
                    status: 'pending',
                    editing: false,
                    effects: [...state.effects, createEffect('submit')],
                };
            }

            if (event.type === 'UPDATE_INCORRECT_EMAIL') {
                return {
                    ...state,
                    emailIncorrect: event.value,
                    effects: [...state.effects],
                };
            }

            if (event.type === 'UPDATE_INCORRECT_PHONE') {
                return {
                    ...state,
                    phoneIncorrect: event.value,
                    effects: [...state.effects],
                };
            }

            if (event.type === 'TOGGLE_EDIT') {
                return {
                    ...state,
                    editing: true,
                    effects: [...state.effects],
                };
            }
            if (event.type === 'DISCARD') {
                return {
                    ...state,
                    editing: false,
                    effects: [...state.effects],
                };
            }
            if (event.type === 'RESET_ERROR_AND_SUCCESS') {
                return {
                    ...state,
                    error: false,
                    success: false,
                    errorMessage: '',
                    effects: [...state.effects],
                };
            }
            return state;
        case 'pending':
            if (event.type === 'RESOLVE') {
                return {
                    ...state,
                    status: 'idle',
                    editing: false,
                    success: true,
                    effects: [...state.effects],
                };
            }
            if (event.type === 'REJECT') {
                return {
                    ...state,
                    status: 'idle',
                    error: true,
                    errorMessage: event.errorMessage,
                    editing: false,
                    effects: [...state.effects],
                };
            }
            return state;
    }
}

export function ContactInfoFormMachine() {
    const [{ effects, ...state }, dispatch] = useReducer(reducer, initialContactStateData);

    useEffect(() => {
        for (const effect of effects) {
            if (effect.status !== 'idle') {
                continue;
            }
            effect.markAsStarted();

            if (effect.type === 'submit') {
                // We need to diff the new values against the existing values
                // and only send the values that actually changed.
                const requestObject = {};
                if (state.phoneIncorrect) {
                    requestObject.phone = '0700000000';
                } else if (state.newPhone.replace('+46', '0') !== state.phone.replace('+46', '0')) {
                    requestObject.phone = state.newPhone;
                }

                if (state.emailIncorrect) {
                    requestObject.email = 'noemail@lendo.se';
                } else if (state.newEmail !== state.email) {
                    requestObject.email = state.newEmail;
                }

                // update contact information request
                ApplicantActions.update(state.applicantId, state.applicationType, requestObject)
                    .then(() => dispatch({ type: 'RESOLVE' }))
                    .catch((err) => dispatch({ type: 'REJECT', errorMessage: err }));
            }
        }
    }, [state, effects]);

    return [state, dispatch];
}
