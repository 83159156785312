import queryString from 'query-string';

import config from '../../config';
import AjaxHelper from '../../helpers/AjaxHelper';

const transport = AjaxHelper;
const baseUrl = config.partnerApi.prefix;
const wonApplicationsUrl = `${baseUrl}/won-applications`;

export default {
    // won applications
    getApplication: (productId, limit, offset, status) => {
        const query = { product_id: productId, limit, offset, status };
        const queryToSend = queryString.stringify(query);
        return transport.get(`${wonApplicationsUrl}?${queryToSend}`, 'data');
    },
};
