import { IntegrationStatus } from 'APP/enums/Integrations/Status';
import React, { Fragment } from 'react';
import { Button, Popup } from 'semantic-ui-react';

export default function RunButton(props) {
    if (props.status === IntegrationStatus.status.FINISHED) {
        return (
            <Popup
                trigger={
                    <Button loading={props.loading} disabled={props.disabled} size="small" primary content="Run" />
                }
                content={
                    <Fragment>
                        <p style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
                            Are you sure you want to rerun this task?
                        </p>
                        <Button fluid size="small" primary content="Rerun task" onClick={props.handleRunClick} />
                    </Fragment>
                }
                on="click"
                size="tiny"
                position="top left"
            />
        );
    }

    return (
        <Button
            loading={props.loading}
            disabled={props.disabled}
            onClick={props.handleRunClick}
            size="small"
            primary
            content="Run"
        />
    );
}
