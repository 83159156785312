import { HandleException } from 'APP/helpers/ErrorHelper';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Grid, Header, Menu, Segment } from 'semantic-ui-react';

import { SearchActions } from '../../../actions/PartnerApiActions';
import GeneralLoader from '../../../components/GeneralLoader';
import NothingToShow from '../../../components/NothingToShowMessage';
import SearchResultsTable from '../../../components/PartnerApplication/SearchResultsTable';

class List extends Component {
    state = {
        applications: [],
    };

    UNSAFE_componentWillMount(props) {
        this.doSearch(props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            this.doSearch(nextProps);
        }
    }

    async doSearch(props = this.props) {
        const { query } = queryString.parse(props.location.search);

        if (!query) {
            return;
        }

        this.setState({ fetching: true });

        try {
            const applications = await SearchActions.get(query);

            this.setState({ applications });
        } catch (error) {
            HandleException(error);
        }

        this.setState({ fetching: false });
    }

    renderContent() {
        if (!this.state.applications.length) {
            return <NothingToShow error={this.state.error} />;
        }

        return <SearchResultsTable applications={this.state.applications} baseURL={this.props.match.url} />;
    }

    renderHeader() {
        const { query } = queryString.parse(this.props.location.search);

        if (!query) {
            return <span>No search query specified, please use the search bar above</span>;
        }

        return (
            <span>
                Search results for <strong>{query}</strong>
            </span>
        );
    }

    render() {
        return (
            <div>
                <Menu attached="top" tabular>
                    <Menu.Item>
                        <Header content="Search results" />
                    </Menu.Item>
                </Menu>

                <Grid padded>
                    <Grid.Column>
                        <Segment>
                            {this.renderHeader()}
                            {this.state.fetching ? <GeneralLoader /> : this.renderContent()}
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

List.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }).isRequired,
};

List.defaultProps = {};

export default List;
