export const VALIDATE_SUCCESS = 'VALIDATE_SUCCESS';
export const VALIDATE_REQUEST = 'VALIDATE_REQUEST';
export const VALIDATE_FAILURE = 'VALIDATE_FAILURE';

export const PASSWORD_CHANGE_REQUEST = 'PASSWORD_CHANGE_REQUEST';
export const PASSWORD_CHANGE_FAILURE = 'PASSWORD_CHANGE_FAILURE';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';

export const GENERATE_PASSWORD_REQUEST = 'GENERATE_PASSWORD_REQUEST';
export const GENERATE_PASSWORD_FAILURE = 'GENERATE_PASSWORD_FAILURE';
export const GENERATE_PASSWORD_SUCCESS = 'GENERATE_PASSWORD_SUCCESS';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const IS_UNAUTHED = 'IS_UNAUTHED';
export const SET_AUTH_STATE = 'SET_AUTH_STATE';
