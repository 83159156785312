import React from 'react';

// eslint-disable-next-line import/no-named-as-default
import NavigationComponent from './NavigationComponent';

export default class NavigationContainer extends React.Component {
    render() {
        return <NavigationComponent />;
    }
}
