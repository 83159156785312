import config from '../config';
import AjaxHelper from '../helpers/AjaxHelper';

const baseUrl = `${config.api.prefix}/applications`;

const transport = AjaxHelper;

export default {
    getBusinessPropertyQuestions: (applicationId) =>
        transport.get(`${baseUrl}/business/property-questions/${applicationId}`, 'data'),

    update: (applicationId, data) => transport.put(`${baseUrl}/business/property-questions/${applicationId}`, { data }),

    post: (data) => transport.put(`${baseUrl}/business/property-questions`, { data }),

    // ----------------------------------------------
};
