import React from 'react';
import { Button } from 'semantic-ui-react';

function DraftOfferButton() {
    return <Button disabled primary basic size="small" content="Draft offer" floated="right" />;
}

DraftOfferButton.propTypes = {};
DraftOfferButton.defaultProps = {};

export default DraftOfferButton;
