import { RepaymentTypes } from 'APP/enums/Application/PrivateLoan/RepaymentTypes';
import ResponseTypes from 'APP/enums/Response/Types';
import DataDisplayHelper from 'APP/helpers/DataDisplayHelper';
import ResponseHelper from 'APP/helpers/ResponseHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { Header, Table } from 'semantic-ui-react';

const rates = [
    {
        key: 'repayment_type',
        label: 'Repayment type',
        render: (f) => RepaymentTypes[f],
    },
    {
        key: 'interest_rate_nominal',
        label: 'Monthly interest rate',
        render: (f) => `${f} %`,
        onlyOn: ResponseTypes.BUSINESS_LOAN_OFFER,
    },
    {
        key: 'interest_rate_nominal',
        label: 'Nominal interest rate',
        render: (f) => `${f} %`,
        onlyOn: ResponseTypes.PRIVATE_LOAN_OFFER,
    },
    {
        key: 'interest_rate_effective',
        label: 'Effective interest rate',
        render: (f) => `${f} %`,
        onlyOn: ResponseTypes.PRIVATE_LOAN_OFFER,
    },
    {
        key: 'amount_to_solve_external',
        label: 'Amount to solve external',
        render: (f) => DataDisplayHelper.currency(f, 'kr'),
        onlyOn: ResponseTypes.PRIVATE_LOAN_OFFER,
    },
    {
        key: 'amount_to_solve_internal',
        label: 'Amount to solve internal',
        render: (f) => DataDisplayHelper.currency(f, 'kr'),
        onlyOn: ResponseTypes.PRIVATE_LOAN_OFFER,
    },
    {
        key: 'monthly_cost_first',
        label: 'First monthly cost',
        render: (f) => DataDisplayHelper.currency(f, 'kr'),
    },
    {
        key: 'monthly_cost_last',
        label: 'Last monthly cost',
        render: (f) => DataDisplayHelper.currency(f, 'kr'),
    },
    {
        key: 'setup_fee',
        label: 'Setup fee',
        render: (f) => DataDisplayHelper.currency(f, 'kr'),
    },
    {
        key: 'administration_fee',
        label: 'Administration fee',
        render: (f) => DataDisplayHelper.currency(f, 'kr'),
    },
    {
        key: 'autogiro_fee',
        label: 'Direct debit fee',
        render: (f) => DataDisplayHelper.currency(f, 'kr'),
    },
    {
        key: 'total_cost',
        label: 'Total cost',
        render: (f) => DataDisplayHelper.currency(f, 'kr'),
        onlyOn: ResponseTypes.BUSINESS_LOAN_OFFER,
    },
    {
        key: 'early_redemption_fee',
        label: 'Early redemption fee',
        render: (f) => (f ? 'Yes' : 'No'),
        onlyOn: ResponseTypes.BUSINESS_LOAN_OFFER,
    },
];

function FeesAndRates(props) {
    return (
        <div>
            <Header as="h4" content="Fees and rates" />
            <Table compact basic="very" size="small" singleLine>
                <Table.Body>
                    {rates
                        .filter((rate) => {
                            switch (rate.onlyOn) {
                                case undefined:
                                    return true;
                                case ResponseTypes.BUSINESS_LOAN_OFFER:
                                    return ResponseHelper.isBusinessLoanOffer(props.response);
                                case ResponseTypes.PRIVATE_LOAN_OFFER:
                                    return ResponseHelper.isPrivateLoanOffer(props.response);
                                default:
                                    return false;
                            }
                        })
                        .map((rate) => {
                            if (props.response[rate.key] === undefined) {
                                return null;
                            }

                            return (
                                <Table.Row key={rate.key}>
                                    <Table.HeaderCell textAlign="left">{rate.label}</Table.HeaderCell>
                                    <Table.Cell>{rate.render(props.response[rate.key])}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                </Table.Body>
            </Table>
        </div>
    );
}

FeesAndRates.propTypes = {
    response: PropTypes.shape({}).isRequired,
};
FeesAndRates.defaultProps = {};

export default FeesAndRates;
