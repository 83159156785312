import { Validation } from '@lendoab/lendo-redux-form-helpers';
import ApplicationTypes from 'APP/enums/Application/Types';
import PRODUCT_REQUIREMENTS from 'APP/enums/Products/Requirements';
import ApplicationHelper from 'APP/helpers/ApplicationHelper';
import ProductHelper from 'APP/helpers/ProductHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { Header, Table } from 'semantic-ui-react';

import BankInformation from './BankInformation';
import ClaimsPepInformation from './ClaimsPepInformation';
import GeneralInformation from './GeneralInformation';
import LoanPurpose from './LoanPurpose';

const { bgValidation: isBg, pgValidation: isPg } = Validation.ValidationFunctions;

function shouldShowProductRequirement(product, productRequirement) {
    const productRequirements = ProductHelper.getProductRequirements(product);
    return productRequirements && productRequirements.value.includes(productRequirement);
}

/* eslint-disable camelcase */
function AdditionalInformationAcceptedOffer(props) {
    const { acceptance, application, product } = props;
    const { parameters } = acceptance;
    let {
        loan_purpose,
        clearing_number,
        pg_number,
        bg_number,
        account_number,
        claims_pep,
        contact_person,
        email, // eslint-disable-line prefer-const
    } = parameters;

    const showClaimsPEP = application.type === ApplicationTypes.BUSINESS;

    /* THIS CODE SHOULD BE REMOVED ONE THE NEW ACCEPTANCE HAS BEEN IN PLACE FOR SOME TIME */
    if (shouldShowProductRequirement(product, PRODUCT_REQUIREMENTS.LOAN_PURPOSE)) {
        loan_purpose = loan_purpose || application.products[0].loan_purpose;
    }

    if (shouldShowProductRequirement(product, PRODUCT_REQUIREMENTS.ACCOUNT_NUMBER_IN_INBOX)) {
        const mainApplicant = ApplicationHelper.getMainApplicant(application);
        const accountData = mainApplicant && mainApplicant.account_data;

        if (isBg(accountData.account_number)) {
            bg_number = bg_number || accountData.account_number;
        } else if (isPg(accountData.account_number)) {
            pg_number = pg_number || accountData.account_number;
        } else {
            account_number = account_number || accountData.account_number;
            clearing_number = clearing_number || accountData.clearing_number;
        }
    }

    /* END OF OLD ACCEPTANCE CODE */

    // If any of these parameters need to exist for the compondent to render
    const shouldRender = [loan_purpose, pg_number, bg_number, account_number, showClaimsPEP].some((p) => !!p);

    if (!shouldRender) {
        return null;
    }

    return (
        <div>
            <Header content="Information provided by the applicant" />
            <Table basic="very" compact>
                <Table.Body>
                    <Table.Row>
                        <LoanPurpose loanPurpose={loan_purpose} product={product} />
                        <GeneralInformation text={contact_person} header="Contact Person" />
                        <GeneralInformation text={email} header="Email" />
                        <BankInformation
                            accountNumber={account_number}
                            clearingNumber={clearing_number}
                            pgNumber={pg_number}
                            bgNumber={bg_number}
                        />
                        {showClaimsPEP && <ClaimsPepInformation pep={claims_pep} />}
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>
    );
}

/* eslint-disable camelcase */
AdditionalInformationAcceptedOffer.propTypes = {
    product: PropTypes.shape({}).isRequired,
    application: PropTypes.shape({
        type: PropTypes.string,
    }).isRequired,
    acceptance: PropTypes.shape({
        loan_purpose: PropTypes.string,
        pg_number: PropTypes.string,
        bg_number: PropTypes.string,
        account_number: PropTypes.string,
        claims_pep: PropTypes.string,
    }).isRequired,
};
/* eslint-enable camelcase */

export default AdditionalInformationAcceptedOffer;
