import queryString from 'query-string';

import config from '../../config';
import AjaxHelper from '../../helpers/AjaxHelper';

const transport = AjaxHelper;
const baseUrl = config.partnerApi.prefix;
const treatApplicationsUrl = `${baseUrl}/treat-applications`;

export default {
    // Treat applications actions
    getApplications: (params = {}) => {
        const query = queryString.stringify(params);
        return transport.get(`${treatApplicationsUrl}?${query}`, 'data');
    },
    getApplication: (id, productId) => transport.get(`${treatApplicationsUrl}/${id}?product_id=${productId}`, 'data'),
};
