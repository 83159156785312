// first we need the EventEmitter module
import EventEmitter from 'events';

const CHANGE_EVENT = 'change';

// we need to inherit the EventEmitter capabilities
class RequestStatusHandler extends EventEmitter {
    constructor() {
        super();
        this.request_queue = [];
        this.request_results = [];
        // waiting, done of failed
        this.status = 'done';
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    getRequestQueue = () => this.request_queue;

    getRequestResults = () => this.request_results;

    getRequestChainName = () => this.request_chain_name;

    addRequest = (name, action) => {
        this.request_queue.push({
            name,
            action,
            status: 'waiting',
        });
        this.emit(CHANGE_EVENT);
    };

    // Name: Name of the chain of requests, ex. Accept Offer, Show Modal: Show intrusive modal that shows the process of the requests
    execute = (name = null, show_modal = false) => {
        this.request_results = [];
        this.request_chain_name = name;
        this.show_modal = show_modal;
        const promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
        this.status = 'waiting';
        this.emit(CHANGE_EVENT);
        show_modal && this.emit('modal_show');
        this.handleQueue();
        return promise;
    };

    getStatus = () => this.status;

    handleQueue = () => {
        const any_active = this.request_queue.some((req) => req.status === 'active');

        if (any_active) {
            return false;
        }

        const request = this.request_queue.find((req) => req.status === 'waiting');
        if (!request) {
            // No more requests, clear, which will set status to done and emit change
            this.status = 'done';
            this.request_results = this.request_queue;
            !this.show_modal && this.emit('message_show');
            this.clearRequestsQueue();
            this.resolve();
            return true;
        }
        request.status = 'active';
        this.emit(CHANGE_EVENT);
        request.action().then(
            () => {
                request.status = 'done';
                this.emit(CHANGE_EVENT);
                this.handleQueue();
            },
            (err) => {
                this.status = 'failed';
                request.status = 'failed';
                request.error = err;
                this.request_results = this.request_queue;
                !this.show_modal && this.emit('message_show');
                this.emit(CHANGE_EVENT);
                this.reject();
            }
        );
    };

    restartAllRequests = () => {
        this.request_queue = this.request_queue.map((req) => {
            const new_req = req;
            new_req.status = 'waiting';
            new_req.error = undefined;
            return new_req;
        });
        this.status = 'waiting';
        this.emit(CHANGE_EVENT);
        this.handleQueue();
    };

    removeRequest = (name) => {
        delete this.request_queue[name];
        this.emit(CHANGE_EVENT);
    };

    clearRequestsQueue = () => {
        this.request_queue = [];
        this.emit(CHANGE_EVENT);
    };
}

const RSH = new RequestStatusHandler();

export default RSH;
