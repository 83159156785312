import Field, { Parsers, Validation } from '@lendoab/lendo-redux-form-helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field as ReduxField, formValueSelector } from 'redux-form';
import { Form, Header } from 'semantic-ui-react';

import InputComponentCustom from './InputComponentCustom';

function PrivateRates(props) {
    const { repaymentType } = props;

    return (
        <div>
            <Header>Rates and fees</Header>
            <Form.Group widths="2">
                <ReduxField
                    validate={[Validation.required, Validation.largerThan(0)]}
                    component={InputComponentCustom}
                    type="number"
                    step="any"
                    name="interest_rate_nominal"
                    formLabel="Nominal interest rate"
                    label="%"
                    parse={Parsers.float}
                    labelPosition="right"
                />
                <ReduxField
                    component={InputComponentCustom}
                    type="number"
                    step="any"
                    name="interest_rate_effective"
                    formLabel="Effective interest rate"
                    placeholder="Will be calculated if left empty"
                    label="%"
                    parse={Parsers.float}
                    labelPosition="right"
                />
            </Form.Group>
            <Form.Group widths="2">
                <Field
                    component="input"
                    type="number"
                    name="monthly_cost_first"
                    validate={[Validation.required, Validation.largerThan(0)]}
                    label="Monthly cost"
                    parse="int"
                    placeholder="Shown to the customer"
                />
                <Field
                    component="input"
                    type="number"
                    name="monthly_cost_last"
                    validate={repaymentType === 'straight' ? [Validation.required] : null}
                    label="Last monthly cost"
                    parse="int"
                />
            </Form.Group>
            <Form.Group widths="2">
                <Field component="input" name="administration_fee" label="Administration fee" parse="int" />
                <Field component="input" name="autogiro_fee" label="Direct debit fee" parse="int" />
            </Form.Group>
            <Form.Group widths="2">
                <Field component="input" name="setup_fee" label="Setup fee" parse="int" />
                <Field component="input" name="invoice_fee" label="Invoice fee" parse="int" />
            </Form.Group>
        </div>
    );
}

PrivateRates.propTypes = {
    repaymentType: PropTypes.string.isRequired,
};
PrivateRates.defaultProps = {};

const selector = formValueSelector('TreatForm');

export default connect((state) => {
    const repaymentType = selector(state, 'repayment_type');

    return {
        repaymentType,
    };
})(PrivateRates);
