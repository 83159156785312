import ResponseHelper from 'APP/helpers/ResponseHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { Segment } from 'semantic-ui-react';

import NewRevisionInformationBanner from './NewRevisionInformationBanner';
import RevisionsTable from './RevisionsTable';

function BusinessRevisions(props) {
    const { application, denyCallback, sendNewOfferCallback, revisions, responses, disableButtons } = props;

    const responsesToCurrentRevision = responses.filter((r) => r.revision === application.revision);

    // If we only have one revision, and no previous responses, the view won't add any value
    if (revisions.length === 1 && responsesToCurrentRevision.length === 0) {
        return null;
    }

    const activeResponsesToCurrentRevision = responsesToCurrentRevision.filter(ResponseHelper.isActive);

    return (
        <Segment.Group>
            {revisions.length > 1 && activeResponsesToCurrentRevision.length === 0 && (
                <NewRevisionInformationBanner
                    productId={props.productId}
                    application={application}
                    denyCallback={denyCallback}
                    sendNewOfferCallback={sendNewOfferCallback}
                />
            )}

            <RevisionsTable
                currentRevision={application.revision}
                revisions={revisions}
                responses={responses}
                resendOfferCallback={props.resendOfferCallback}
                withdrawOfferCallback={props.withdrawOfferCallback}
                disableButtons={disableButtons}
            />
        </Segment.Group>
    );
}

BusinessRevisions.propTypes = {
    productId: PropTypes.string.isRequired,
    revisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    responses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    application: PropTypes.shape({}).isRequired,
    sendNewOfferCallback: PropTypes.func.isRequired,
    denyCallback: PropTypes.func.isRequired,
    resendOfferCallback: PropTypes.func.isRequired,
    withdrawOfferCallback: PropTypes.func.isRequired,
    disableButtons: PropTypes.bool.isRequired,
};
BusinessRevisions.defaultProps = {};

export default BusinessRevisions;
