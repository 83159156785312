import ResponseHelper from 'APP/helpers/ResponseHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { Step } from 'semantic-ui-react';

import STEPS from './Steps';

function ResponseStep(props) {
    const { response, activeStep, isDisabledFunction } = props;

    if (!response || ResponseHelper.isLoanOffer(response)) {
        return (
            <Step
                disabled={isDisabledFunction(activeStep, STEPS.OFFER_GIVEN)}
                active={activeStep === STEPS.OFFER_GIVEN}
                title="Offer given"
                description="The applicant has received your offer"
            />
        );
    }

    return (
        <Step
            disabled={isDisabledFunction(activeStep, STEPS.OFFER_GIVEN)}
            active={activeStep === STEPS.DENIAL_GIVEN}
            title="Application denied"
            description="The application has been denied"
        />
    );
}

ResponseStep.propTypes = {
    response: PropTypes.shape({}),
    activeStep: PropTypes.string.isRequired,
    isDisabledFunction: PropTypes.func.isRequired,
};
ResponseStep.defaultProps = {
    response: null,
};

export default ResponseStep;
