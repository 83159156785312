import { captureMessage } from '@sentry/react';

import config from '../../config';
import ResponseTypes from '../../enums/Response/Types';
import AjaxHelper from '../../helpers/AjaxHelper';

const transport = AjaxHelper;
const responseURLPrefix = `${config.partnerApi.prefix}/responses`;
const privateOffersUrl = `${responseURLPrefix}/loan-offers`;
const businessOffersUrl = `${responseURLPrefix}/business-loan-offers`;
const denialsUrl = `${responseURLPrefix}/denials`;
const demandCoUrl = `${responseURLPrefix}/requests`;

const offerTypeURL = {
    [ResponseTypes.PRIVATE_LOAN_OFFER]: privateOffersUrl,
    [ResponseTypes.BUSINESS_LOAN_OFFER]: businessOffersUrl,
    [ResponseTypes.DENIAL]: denialsUrl,
};

// getOfferURL returns correct URL based on offer type, it defaults to private loan offer URL.
const getOfferURL = (offerType) => {
    return offerTypeURL[offerType] || privateOffersUrl;
};

export default {
    create: (offer, type) => transport.post(getOfferURL(type), { data: offer }, 'data'),

    patchOffer(offer, data) {
        return transport.patch(`${getOfferURL(offer.type)}/${offer.id}`, { data });
    },

    deny: (denial) => transport.post(denialsUrl, { data: denial }, 'data'),

    demandCo: (data) =>
        new Promise((resolve, reject) => {
            const url = `${demandCoUrl}`;
            transport.post(url, { data }).then((response) => {
                if (
                    response.data.status === 'OK' &&
                    typeof response.data.data === 'object' &&
                    response.data.data !== null
                ) {
                    return resolve(response.data.data);
                }
                reject();
            }, reject);
        }),

    withdrawOffer(offer, { reason } = {}) {
        const data = {
            status: 'withdrawn',
        };

        if (reason) {
            data.withdrawal_reason = reason;
        } else if (offer.type === 'business_loan_offer') {
            captureMessage(
                `A business withdrawal with and empty withdrawal_reason happened. Application id: ${offer.application_id}`
            );
        }

        return this.patchOffer(offer, data);
    },

    async resendOffer(offer, revision, data = {}) {
        await this.withdrawOffer(offer, data);

        const newOffer = { ...offer, revision, status: 'active' };

        delete newOffer.id;
        delete newOffer.legacy_id;

        return transport.post(getOfferURL(offer.type), { data: newOffer });
    },

    markContractSent(offer) {
        const data = {
            state: 'contract_sent',
        };

        return this.patchOffer(offer, data);
    },

    markPaidOut(offer) {
        const data = {
            state: 'loan_paid',
        };

        return this.patchOffer(offer, data);
    },

    markPaidOutWithAmount(offer, amount) {
        const data = {
            state: 'loan_paid',
            amount_paid: amount,
        };

        return this.patchOffer(offer, data);
    },
};
