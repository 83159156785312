import ApplicationTypes from 'APP/enums/Application/Types';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Label, Table } from 'semantic-ui-react';

import ApplicationHelper from '../../../helpers/ApplicationHelper';
import DataDisplayHelper from '../../../helpers/DataDisplayHelper';
import ViewApplicationButton from '../ViewApplicationButton';

function Row(props) {
    const { baseURL, product, application } = props;
    const isMortgage = application.type === ApplicationTypes.MORTGAGE;

    const loanAmount = isMortgage
        ? ApplicationHelper.getTotalLoanAmount(application)
        : application.products[0].total_loan_amount;
    const amortizeLength = isMortgage ? '-' : ApplicationHelper.renderPeriod(application.products[0].amortize_length);

    return (
        <Table.Row key={application.id}>
            <Table.Cell>
                <Label>{moment(application.created_at).format('lll')}</Label>
            </Table.Cell>
            <Table.Cell>{ApplicationHelper.shortId(application.id)}</Table.Cell>
            <Table.Cell>{ApplicationHelper.getIdOfApplicantOrCompany(application)}</Table.Cell>
            <Table.Cell>{DataDisplayHelper.currency(loanAmount, 'kr')}</Table.Cell>
            <Table.Cell>{amortizeLength}</Table.Cell>
            <Table.Cell>{product.name}</Table.Cell>
            <Table.Cell>
                {isMortgage ? (
                    <Button
                        as={Link}
                        to={`/treatapplications/${product.id}/mortgage/applications/${application.id}`}
                        primary
                    >
                        View
                    </Button>
                ) : (
                    <ViewApplicationButton applicationId={application.id} baseURL={baseURL} productId={product.id} />
                )}
            </Table.Cell>
        </Table.Row>
    );
}

/* eslint-disable camelcase */
Row.propTypes = {
    application: PropTypes.shape({
        id: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        applicants: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
                ssn: PropTypes.string,
            })
        ).isRequired,
        company: PropTypes.shape({
            registration_number: PropTypes.string,
        }),
        products: PropTypes.arrayOf(
            PropTypes.shape({
                total_loan_amount: PropTypes.number,
                amortize_length: PropTypes.number,
            })
        ).isRequired,
        status: PropTypes.string,
    }).isRequired,
    product: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
    }).isRequired,
    baseURL: PropTypes.string.isRequired,
};
/* eslint-enable camelcase */

Row.defaultProps = {};

export default Row;
