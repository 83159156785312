import StatusColors from '../../config/application/status_colors';

const statuses = {
    CREATED: 'created',
    PREREQUISITES_COMPLETED: 'prerequisites_completed',
    CHANGE_CREATED: 'change_created',
    PAUSED: 'paused',
    PRODUCTS_RESPONDED: 'products_responded',
    BILL_OF_DEBT_SENT: 'bill_of_debt_sent',
    USER_ACCEPTED_OFFER: 'user_accepted_offer',
    APPROVED: 'approved',
    LOAN_PAID: 'loan_paid',
    ABORTED: 'aborted',
    CANCELLED: 'cancelled',
    DENIED: 'denied',
};

export default statuses;

// The statuses during which an application should have a accept object to fetch
export const statusesWithAccept = [statuses.BILL_OF_DEBT_SENT, statuses.USER_ACCEPTED_OFFER, statuses.LOAN_PAID];

export const explanations = {
    created: 'The application is saved and Lendo system is collecting required data (UC, PEP etc)',
    prerequisites_completed: 'The required data has been collected (UC, PEP etc)',
    change_created: 'The application is changed and Lendo system is collecting additional required data (UC, PEP etc)',
    paused: 'The application is paused',
    products_responded: 'All expected products have responded with either an offer or a denial',
    bill_of_debt_sent: 'A bill of debt has been sent to the customer and is awaiting signing',
    user_accepted_offer: 'The user has accepted offer(s)',
    approved: 'At least one offer was sent from a partner',
    loan_paid: 'The loan has been paid out',
    aborted: 'The application has been aborted. One applicant may be sanctioned',
    cancelled: 'The application has been cancelled. Applicant may not exist in UC',
    user_unaccepted_offer: 'The acceptance was withdrawn',
    denied: 'All products denied within the application timeframe',
};

export const pretty = {
    created: 'Created',
    prerequisites_completed: 'Prerequisites completed',
    change_created: 'Change created',
    paused: 'Paused',
    products_responded: 'Products responded',
    bill_of_debt_sent: 'Bill of debt sent',
    user_accepted_offer: 'User accepted offer',
    approved: 'Approved',
    loan_paid: 'Loan paid',
    aborted: 'Aborted',
    cancelled: 'Cancelled',
    user_unaccepted_offer: 'User unaccepted offer',
    denied: 'Denied',
};

export const options = [
    {
        text: 'Created',
        value: 'created',
        label: { color: StatusColors.created, empty: true, circular: true },
    },
    {
        text: 'Prerequisites completed',
        value: 'prerequisites_completed',
        label: { color: StatusColors.prerequisites_completed, empty: true, circular: true },
    },
    {
        text: 'Change created',
        value: 'change_created',
        label: { color: StatusColors.change_created, empty: true, circular: true },
    },
    {
        text: 'Paused',
        value: 'paused',
        label: { color: StatusColors.paused, empty: true, circular: true },
    },
    {
        text: 'Products responded',
        value: 'products_responded',
        label: { color: StatusColors.products_responded, empty: true, circular: true },
    },
    {
        text: 'Bill of debt sent',
        value: 'bill_of_debt_sent',
        label: { color: StatusColors.bill_of_debt_sent, empty: true, circular: true },
    },
    {
        text: 'User accepted offer',
        value: 'user_accepted_offer',
        label: { color: StatusColors.user_accepted_offer, empty: true, circular: true },
    },
    {
        text: 'Approved',
        value: 'approved',
        label: { color: StatusColors.approved, empty: true, circular: true },
    },
    {
        text: 'Loan paid',
        value: 'loan_paid',
        label: { color: StatusColors.loan_paid, empty: true, circular: true },
    },
    {
        text: 'Aborted',
        value: 'aborted',
        label: { color: StatusColors.aborted, empty: true, circular: true },
    },
    {
        text: 'User unaccepted Offer',
        value: 'user_unaccepted_offer',
        label: { color: StatusColors.user_unaccepted_offer, empty: true, circular: true },
    },
    {
        text: 'Cancelled',
        value: 'cancelled',
        label: { color: StatusColors.cancelled, empty: true, circular: true },
    },
    {
        text: 'Denied',
        value: 'denied',
        label: { color: StatusColors.denied, empty: true, circular: true },
    },
];

export const statusOptions = {
    created: {
        label: 'Created',
        value: 'created',
        style: { color: StatusColors.created, empty: true, circular: true },
    },
    prerequisites_completed: {
        label: 'Prerequisites completed',
        value: 'prerequisites_completed',
        style: { color: StatusColors.prerequisites_completed, empty: true, circular: true },
    },
    change_created: {
        label: 'Change created',
        value: 'change_created',
        style: { color: StatusColors.change_created, empty: true, circular: true },
    },
    products_responded: {
        label: 'Products responded',
        value: 'products_responded',
        style: { color: StatusColors.products_responded, empty: true, circular: true },
    },
    bill_of_debt_sent: {
        label: 'Bill of debt sent',
        value: 'bill_of_debt_sent',
        style: { color: StatusColors.bill_of_debt_sent, empty: true, circular: true },
    },
    user_accepted_offer: {
        label: 'User accepted offer',
        value: 'user_accepted_offer',
        style: { color: StatusColors.user_accepted_offer, empty: true, circular: true },
    },
    approved: {
        label: 'Approved',
        value: 'approved',
        style: { color: StatusColors.approved, empty: true, circular: true },
    },
    loan_paid: {
        label: 'Loan paid',
        value: 'loan_paid',
        style: { color: StatusColors.loan_paid, empty: true, circular: true },
    },
    cancelled: {
        label: 'Cancelled',
        value: 'cancelled',
        style: { color: StatusColors.cancelled, empty: true, circular: true },
    },
    denied: {
        label: 'Denied',
        value: 'denied',
        style: { color: StatusColors.denied, empty: true, circular: true },
    },
};
