import Field, { Parsers, Validation } from '@lendoab/lendo-redux-form-helpers';
import ExplanationPopup from 'APP/common/ExplanationPopup';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field as ReduxFormField, formValueSelector } from 'redux-form';
import { Form, Header } from 'semantic-ui-react';

import InputComponentCustom from './InputComponentCustom';

function TotalCostLabel() {
    return (
        <div>
            Total cost
            <ExplanationPopup>
                <p>Total amount to pay for the entire period</p>

                <p>
                    Total cost = <br />
                    Loan amount + <br />
                    Total interest cost + <br />
                    Eventual setup fee + <br />
                    Lowest possible other fees (e.g. direct debit/administration fee)
                </p>
            </ExplanationPopup>
        </div>
    );
}

function MonthlyCostLabel() {
    return (
        <div>
            Monthly cost
            <ExplanationPopup>
                <p>The first monthly cost that the customer has to pay</p>

                <p>
                    Monthly cost = <br />
                    Amortization + <br />
                    Interest costs + <br />
                    Lowest possible other monthly fees (e.g. direct debit/administration fee)
                    {/* (+ om det skulle vara en annan typ av kostnad som vi då måste få information om för att kunna specificera). */}
                </p>
            </ExplanationPopup>
        </div>
    );
}

function intValidation(value) {
    if (!/^[0-9]*$/.test(value)) {
        return 'Only integers are allowed';
    }
}

function BusinessRates(props) {
    const { repaymentType, amount } = props;

    return (
        <div>
            <Header>Rates and fees</Header>
            <Form.Group widths="2">
                <ReduxFormField
                    validate={[Validation.required, Validation.largerThan(0), Validation.maxValue(6)]}
                    component={InputComponentCustom}
                    type="number"
                    step="any"
                    name="interest_rate_nominal"
                    formLabel="Monthly interest rate"
                    label="%"
                    parse={Parsers.float}
                    labelPosition="right"
                />
                <Field
                    component="input"
                    type="number"
                    name="total_cost"
                    label={<TotalCostLabel />}
                    validate={[intValidation, Validation.required, Validation.largerThan(amount)]}
                    parse={Parsers.float}
                    placeholder="Interest cost and all fees included"
                />
            </Form.Group>
            <Form.Group widths="2">
                <Field
                    component="input"
                    type="number"
                    name="monthly_cost_first"
                    validate={[intValidation, Validation.required, Validation.largerThan(0)]}
                    label={<MonthlyCostLabel />}
                    parse={Parsers.float}
                    placeholder="Shown to the customer"
                />
                {repaymentType === 'straight' && (
                    <Field
                        component="input"
                        type="number"
                        name="monthly_cost_last"
                        validate={[Validation.required]}
                        label="Last monthly cost"
                        parse="int"
                    />
                )}
            </Form.Group>
            <Form.Group widths="2">
                <Field component="input" name="administration_fee" label="Administration fee" parse="int" />
                <Field component="input" name="autogiro_fee" label="Direct debit fee" parse="int" />
            </Form.Group>
            <Form.Group widths="2">
                <Field component="input" name="setup_fee" label="Setup fee" parse="int" />
                <Field component="input" name="invoice_fee" label="Invoice fee" parse="int" />
            </Form.Group>
            <Form.Field>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <label htmlFor="early_redemption_fee">Early redemption fee</label>
                <Field component="checkbox" name="early_redemption_fee" label="Require early redemption fee" />
            </Form.Field>
        </div>
    );
}

BusinessRates.propTypes = {
    repaymentType: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
};

const selector = formValueSelector('TreatForm');
export default connect((state) => ({
    repaymentType: selector(state, 'repayment_type'),
    amount: selector(state, 'amount'),
}))(BusinessRates);
