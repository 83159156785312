import PropTypes from 'prop-types';
import React from 'react';

export default function Address(props) {
    return (
        <span>
            {props.street} <br />
            {props.postal_code} {props.city} <br />
            {props.country}
        </span>
    );
}

Address.propTypes = {
    street: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    postal_code: PropTypes.string.isRequired,
};
