import { RepaymentTypes } from 'APP/enums/Application/PrivateLoan/RepaymentTypes';
import ApplicationHelper from 'APP/helpers/ApplicationHelper';
import DataDisplayHelper from 'APP/helpers/DataDisplayHelper';
import DateHelper from 'APP/helpers/DateHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { Header, Table } from 'semantic-ui-react';

function PrivateAcceptedOffer(props) {
    return (
        <Table basic="very">
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <Header as="h4" sub content="Amount" />
                        <strong>{DataDisplayHelper.currency(props.offer.amount, 'kr')} </strong>
                    </Table.Cell>

                    <Table.Cell>
                        <Header as="h4" sub content="Amortize length" />
                        <strong>{ApplicationHelper.renderPeriod(props.offer.amortize_length)}</strong>
                    </Table.Cell>

                    <Table.Cell>
                        <Header as="h4" sub content="Nominal interest rate" />
                        <strong>{DataDisplayHelper.decimals(props.offer.interest_rate_nominal)} %</strong>
                    </Table.Cell>

                    <Table.Cell>
                        <Header as="h4" sub content="Effective interest rate" />
                        <strong>{DataDisplayHelper.decimals(props.offer.interest_rate_effective)} %</strong>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Header as="h4" sub content="Amount to solve internal" />
                        <span>{DataDisplayHelper.currency(props.offer.amount_to_solve_internal, 'kr')}</span>
                    </Table.Cell>
                    <Table.Cell>
                        <Header as="h4" sub content="Amount to solve external" />
                        <span>{DataDisplayHelper.currency(props.offer.amount_to_solve_external, 'kr')}</span>
                    </Table.Cell>
                    <Table.Cell>
                        <Header as="h4" sub content="First monthly cost" />
                        <span>{DataDisplayHelper.currency(props.offer.monthly_cost_first, 'kr')}</span>
                    </Table.Cell>
                    <Table.Cell>
                        <Header as="h4" sub content="Last monthly cost" />
                        <span>{DataDisplayHelper.currency(props.offer.monthly_cost_last, 'kr')}</span>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Header as="h4" sub content="Setup fee" />
                        <span>{DataDisplayHelper.currency(props.offer.setup_fee, 'kr')}</span>
                    </Table.Cell>
                    <Table.Cell>
                        <Header as="h4" sub content="Direct debit fee" />
                        <span>{DataDisplayHelper.currency(props.offer.autogiro_fee, 'kr')}</span>
                    </Table.Cell>
                    <Table.Cell>
                        <Header as="h4" sub content="Administration fee" />
                        <span>{DataDisplayHelper.currency(props.offer.administration_fee, 'kr')}</span>
                    </Table.Cell>
                    <Table.Cell>
                        <Header as="h4" sub content="Invoice fee" />
                        <span>{DataDisplayHelper.currency(props.offer.invoice_fee, 'kr')}</span>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Header as="h4" sub content="Offer sent at" />
                        <span>{DateHelper.asYmdHis(props.offer.created_at)}</span>
                    </Table.Cell>
                    <Table.Cell>
                        <Header as="h4" sub content="Repayment type" />
                        <span>{RepaymentTypes[props.offer.repayment_type]}</span>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
}

PrivateAcceptedOffer.propTypes = {
    offer: PropTypes.shape({}).isRequired,
};
PrivateAcceptedOffer.defaultProps = {};

export default PrivateAcceptedOffer;
