export default {
    getCsrf() {
        try {
            return window.localStorage.getItem('csrf');
        } catch (error) {
            return this.csrf;
        }
    },

    setCsrf(csrf) {
        try {
            window.localStorage.setItem('csrf', csrf);
        } catch (error) {
            this.csrf = csrf;
        }
    },
};
