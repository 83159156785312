export default {
    PROGRESS: {
        USER_CANCEL: 'USER_CANCEL',
        COMPLETE: 'COMPLETE',
        NO_USER: 'NO_USER',
        NO_CLIENT: 'NO_CLIENT',
        TIMEOUT_ERROR: 'TIMEOUT_ERROR',
        UNKNOWN_ERROR: 'UNKNOWN_ERROR',
        RESUME_POLLING: 'RESUME_POLLING',
    },
    WAIT_TIME: 1000,
};
