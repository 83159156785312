export default {
    UNTREATED: 'untreated',

    OFFER_GIVEN: 'offer_given',
    DENIAL_GIVEN: 'denial_given',

    USER_ACCEPTED: 'user_accepted',
    BOD_SENT: 'bill_of_debt_sent',
    LOAN_PAID: 'loan_paid',
};
