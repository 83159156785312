import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input, Label } from 'semantic-ui-react';

function InputComponentCustom(props) {
    const {
        formLabel,
        width,
        meta: { touched, error, warning },
        ...rest
    } = props;

    return (
        <Form.Field error={touched && !!error} width={width}>
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            {formLabel && <label>{formLabel}</label>}
            <Input {...rest} />
            {touched && error && (
                <Label pointing color="red">
                    {error}
                </Label>
            )}
            {touched && warning && (
                <Label pointing color="yellow">
                    {warning}
                </Label>
            )}
        </Form.Field>
    );
}

InputComponentCustom.propTypes = {
    formLabel: PropTypes.string,
    width: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
        warning: PropTypes.string,
    }).isRequired,
};

InputComponentCustom.defaultProps = {
    formLabel: undefined,
    width: undefined,
};

export default InputComponentCustom;
