export const marketingEmailDescription =
    'Marketing emails are emails sent to a specific email. Opting out will stop Lendo from sending newsletter or other marketing emails to the specific email registered on that application.';

export const transactionalEmailDescription =
    'Transactional emails are automatic emails sent regarding a specific application (acceptance email, offer email etc), this is connected to the email in the application. Opting out will inactivate all transactional emails connected to the email registered on that application.';

export const marketingSMSDescription =
    'Marketing SMS are SMS sent to a specific phone number (phone number registered in the application). Opting out will stop Lendo from sending marketing SMS to the specific phone number registered on that application.';

export const transactionalSMSDescription =
    'Transactional SMS are automatic sms sent regarding a specific application (acceptance sms, offer sms etc), this is connected to the phone number in the application. Opting out will inactivate all transactional SMS connected to the phone number registered on the application.';

export const smartServiceUseDescription =
    'Smart services are services that can use ML-models in order to improve both type of communication and content. Opting out will lead to this customer not being part of our smart services communications.';

export const smartServiceImproveDescription =
    'In order to build smart services that our customers can benefit from we need data to build new, and improve current, ML-models. If a ML-model contains personal data it is always de-identified. Opting out will make this customers data not available for improving smart services.';

export const CONSENT_STATES = {
    LEGITIMATE: 'legitimate',
    OPT_OUT: 'opt-out',
    OPT_IN: 'opt-in',
};

export const CONSENT_STATE_NAMES = {
    [CONSENT_STATES.LEGITIMATE]: 'Legitimate interest',
    [CONSENT_STATES.OPT_OUT]: 'Opted out',
    [CONSENT_STATES.OPT_IN]: 'Opted in',
};
