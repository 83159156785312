import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form } from 'semantic-ui-react';

const ReminderForm = ({ renewToken, logout }) => (
    <Form>
        <Form.Field>
            <Button type="button" positive onClick={renewToken} content="Renew" size="large" />
            <Button type="button" onClick={logout} content="Close session" size="large" />
        </Form.Field>
    </Form>
);

ReminderForm.propTypes = {
    renewToken: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
};

export default ReminderForm;
