import Field, { Validation } from '@lendoab/lendo-redux-form-helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';

function PaidOutForm(props) {
    const { handleSubmit, paidOutAction, closeAction } = props;
    return (
        <Form onSubmit={handleSubmit(paidOutAction)}>
            <Field
                component="input"
                type="number"
                name="paid_out_amount"
                validate={[Validation.required]}
                label="AMOUT TO PAY OUT"
                parse="int"
            />
            <Form.Group>
                <Form.Button type="submit" content="Mark loan paid out" primary />
                <Form.Button type="button" content="Cancel" onClick={closeAction} />
            </Form.Group>
        </Form>
    );
}

PaidOutForm.propTypes = {
    closeAction: PropTypes.func.isRequired,
    paidOutAction: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'PaidOutForm',
    enableReinitialize: false,
})(PaidOutForm);
