import { getDropdownOptions } from '../../helpers/CommonHelper';

export const Enum = {
    EARLY_PENSION: 'early_pension',
    FINITE_TIME: 'finite_time',
    FULL_TIME: 'full_time',
    HOURLY_EMPLOYMENT: 'hourly_employment',
    OWN_COMPANY: 'own_company',
    PART_TIME: 'part_time',
    STUDENT: 'student',
    PENSION: 'pension',
    UNEMPLOYED: 'unemployed',
};

export const OccupationTexts = {
    [Enum.EARLY_PENSION]: 'Early pensioner',
    [Enum.FINITE_TIME]: 'Limited employment',
    [Enum.FULL_TIME]: 'Permanent employment',
    [Enum.HOURLY_EMPLOYMENT]: 'Extra work (hourly paid)',
    [Enum.OWN_COMPANY]: 'Own company',
    [Enum.PART_TIME]: 'Part time employment',
    [Enum.STUDENT]: 'Student',
    [Enum.PENSION]: 'Retired',
    [Enum.UNEMPLOYED]: 'Unemployed',
};

export const Options = getDropdownOptions(OccupationTexts);

export const MortgageEmploymentTypes = {
    PERMANENT_EMPLOYMENT: 'permanent_employment',
    SELF_EMPLOYMENT: 'self_employment',
    TEMPORARY_EMPLOYMENT: 'temporary_employment',
    SUBSTITUTE_EMPLOYMENT: 'substitute_employment',
    PROBATIONARY_EMPLOYMENT: 'probationary_employment',
    HOURLY_EMPLOYMENT: 'hourly_employment',
    RETIREMENT: 'retirement',
    EARLY_RETIREMENT: 'early_retirement',
    DISABILITY_RETIREMENT: 'disability_retirement',
    STUDENT: 'student',
    NO_EMPLOYMENT: 'no_employment',
};

export const MortgageEmploymentTypeNames = {
    [MortgageEmploymentTypes.PERMANENT_EMPLOYMENT]: {
        eng: 'Permanent employment',
        swe: 'Fast anställning / tillsvidare',
    },
    [MortgageEmploymentTypes.SELF_EMPLOYMENT]: { eng: 'Self employment', swe: 'Egenföretagare' },
    [MortgageEmploymentTypes.TEMPORARY_EMPLOYMENT]: {
        eng: 'Temporary employment',
        swe: 'Visstidsanställning / projekt',
    },
    [MortgageEmploymentTypes.SUBSTITUTE_EMPLOYMENT]: { eng: 'Substitute employment', swe: 'Vikariat' },
    [MortgageEmploymentTypes.PROBATIONARY_EMPLOYMENT]: { eng: 'Probationary period', swe: 'Provanställning' },
    [MortgageEmploymentTypes.HOURLY_EMPLOYMENT]: { eng: 'Employed by the hour', swe: 'Timanställning' },
    [MortgageEmploymentTypes.RETIREMENT]: { eng: 'Old-age retirement', swe: 'Ålderspensionär' },
    [MortgageEmploymentTypes.EARLY_RETIREMENT]: { eng: 'Early retirement', swe: 'Förtidspensionär' },
    [MortgageEmploymentTypes.DISABILITY_RETIREMENT]: { eng: 'Disability retirement', swe: 'Sjukpensionär' },
    [MortgageEmploymentTypes.STUDENT]: { eng: 'Student', swe: 'Student' },
    [MortgageEmploymentTypes.NO_EMPLOYMENT]: { eng: 'Unemployed', swe: 'Arbetslös' },
};
