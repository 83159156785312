import ShortId from 'APP/common/ShortId';
import ApplicationData from 'APP/components/Application/View/ApplicationData';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Header, Menu } from 'semantic-ui-react';

function ApplicationHeader(props) {
    return (
        <Menu secondary>
            <Menu.Item onClick={props.rerouteToList} content="Back" icon="arrow left" />
            <Menu.Item>
                <Header as="h2">
                    ID: <ShortId application={props.application} />, revision: {props.application.revision}
                    <Header.Subheader>
                        Application created: {moment(props.application.created_at).format('lll')}
                    </Header.Subheader>
                </Header>
            </Menu.Item>
            <ApplicationData application={props.application} asMenu />
        </Menu>
    );
}

/* eslint-disable camelcase */
ApplicationHeader.propTypes = {
    application: PropTypes.shape({
        revision: PropTypes.number.isRequired,
        created_at: PropTypes.string.isRequired,
    }).isRequired,
    rerouteToList: PropTypes.func.isRequired,
};
ApplicationHeader.defaultProps = {};

export default ApplicationHeader;
