export default {
    mock: {
        actions: false,
    },
    api: {
        prefix: '/api',
    },
    partnerApi: {
        prefix: '/partner-api',
    },
};
