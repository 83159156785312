import AjaxHelper from '../helpers/AjaxHelper';

const transport = AjaxHelper;

export default {
    changeUserPassword(credentials) {
        const data = {
            data: {
                new_password: credentials.password,
                current_password: credentials.current_password,
            },
        };
        return transport.patch(`/api/me/password`, data);
    },

    generatePassword() {
        return transport.get('/api/me/generate-password');
    },
};
