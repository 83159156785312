import { Box, Typography } from '@lendoab/aphrodite';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

function UploadedFiles(props) {
    const { files, className } = props;

    const downloadAllLocal = () => {
        function downloadFile(i) {
            if (i >= files.length) {
                return;
            }
            const file = files[i];
            const anchor = document.createElement('a');
            anchor.download = file.metadata.name;
            anchor.href = file.download_url;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            // We need a timeout otherwise it is only downloading the last one
            setTimeout(() => {
                downloadFile(i + 1);
            }, 500);
        }
        downloadFile(0);
    };

    const filesNodes = files.map((file) => {
        return (
            <div className="file" key={`${file.id}`}>
                <span>{file.metadata.name}</span>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <a href={file.download_url} download>
                        Download
                    </a>
                    <Icon name="download" className="download" color="blue" />
                </div>
            </div>
        );
    });
    return (
        <div className={className}>
            <Box display="flex" justifyContent="space-between" marginBottom="base">
                <Typography.Title level={5}>Uploaded files</Typography.Title>
                <Button size="small" type="button" onClick={downloadAllLocal}>
                    Download All
                    <Icon name="download" className="download" color="blue" />
                </Button>
            </Box>
            {filesNodes}
        </div>
    );
}

export default UploadedFiles;
