export default {
    /**
     * Set data.x.y from "x.y"
     *
     * @param {{}} data
     * @param {string} key
     * @param {*} value
     */
    dotSet(data, key, value) {
        if (typeof key === 'string') {
            key = key.split('.');
        }
        const k = key.shift();
        if (key.length === 0) {
            data[k] = value;
        } else {
            if (typeof data[k] === 'undefined') {
                data[k] = {};
            }
            this.dotSet(data[k], key, value);
        }
    },

    /**
     * Get data.x.y from "x.y"
     *
     * @param {{}} data
     * @param {string} key
     * @returns {*}
     */
    dotGet(data, key) {
        if (typeof key === 'string') {
            key = key.split('.');
        }
        const k = key.shift();
        if (key.length === 0 || typeof data[k] !== 'object') {
            return data[k];
        }
        return this.dotGet(data[k], key);
    },
    get(obj, key) {
        return key.split('.').reduce((o, x) => (typeof o === 'undefined' || o === null ? o : o[x]), obj);
    },
    /**
     * Deep clone object/array
     *
     * @param {{}} object
     * @param {{}|[]} base
     * @returns {{}|[]}
     */
    clone(object, base = undefined) {
        const ret = base || (Array.isArray(object) ? [] : {});
        for (const i in object) {
            if (object.hasOwnProperty(i)) {
                if (object[i] === null) {
                    ret[i] = null;
                    continue;
                }
                if (Array.isArray(object[i])) {
                    ret[i] = this.clone(object[i], []);
                } else if (typeof object[i] === 'object') {
                    ret[i] = this.clone(object[i]);
                } else {
                    ret[i] = object[i];
                }
            }
        }
        return ret;
    },

    /**
     * Removes empty string values
     *
     * @param {{}} obj
     * @returns {{}}
     */
    removeEmpty(obj) {
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != ''));
    },
};
