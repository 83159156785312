function sumAllUnreadCounts(notifications) {
    if (notifications?.length > 0) {
        return notifications.reduce((counter, obj) => {
            if (obj.unread_count && obj.unread_count > 0) {
                counter += obj.unread_count;
            }
            return counter;
        }, 0);
    }

    return 0;
}

export default {
    sumAllUnreadCounts,
};
