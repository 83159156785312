export default {
    created: 'yellow',
    change_created: 'yellow',
    prerequisites_completed: 'blue',
    paused: 'grey',
    approved: 'olive',
    products_responded: 'teal',
    bill_of_debt_sent: 'purple',
    aborted: 'red',
    cancelled: 'orange',
    denied: 'red',
    loan_paid: 'green',
    user_accepted_offer: 'green',
    user_unaccepted_offer: 'green',
};
