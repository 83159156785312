import Statuses from '../../enums/Application/Statuses';

export default {
    // Decides which of the statuses of all revisions are the dominant one, meaning it has priority in presentation of the status
    // All of the statuses doesnt need to be included, only the ones which are most important
    DOMINANCE_PRIORITY: [
        Statuses.LOAN_PAID,
        Statuses.BILL_OF_DEBT_SENT,
        Statuses.USER_ACCEPTED_OFFER,
        Statuses.APPROVED,
    ],
};
