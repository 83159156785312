import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Label, Table } from 'semantic-ui-react';

import ApplicationHelper from '../../../../helpers/ApplicationHelper';
import DataDisplayHelper from '../../../../helpers/DataDisplayHelper';
import DateHelper from '../../../../helpers/DateHelper';
import ResponseRow from './ResponseRow';
import style from './Revisions.css';

class Revisions extends React.Component {
    state = { expandedRevisions: [] };

    UNSAFE_componentWillMount() {
        this.showHighestRevisionWithResponses(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.showHighestRevisionWithResponses(nextProps);
    }

    showHighestRevisionWithResponses(props) {
        const { revision: highestRevisionWithResponses } = props.responses.reduce(
            (a, b) => (a.revision > b.revision ? a : b),
            {}
        );

        if (this.state.expandedRevisions.includes(highestRevisionWithResponses)) {
            return;
        }

        this.setState({ expandedRevisions: [...this.state.expandedRevisions, highestRevisionWithResponses] });
    }

    toggleExpandedRevision = (revision) => () => {
        const expandedRevisions = this.state.expandedRevisions.slice();
        const index = expandedRevisions.indexOf(revision);
        if (index === -1) {
            expandedRevisions.push(revision);
        } else {
            expandedRevisions.splice(index, 1);
        }

        this.setState({ expandedRevisions });
    };

    render() {
        const { props } = this;

        if (!props.revisions.length) {
            return null;
        }

        const previousRevisions = props.revisions.sort((a, b) => b.revision - a.revision);

        return (
            <Table basic="very" selectable>
                <Table.Body>
                    {previousRevisions.map((applicationRevision) => {
                        const expanded = this.state.expandedRevisions.includes(applicationRevision.revision);

                        return [
                            <Table.Row key={applicationRevision.revision}>
                                <Table.Cell>
                                    <Label content={`Revision ${applicationRevision.revision}`} />
                                </Table.Cell>
                                <Table.Cell>
                                    {DateHelper.formatDate(applicationRevision.created_at, 'YYYY-MM-DD')}
                                </Table.Cell>
                                <Table.Cell>
                                    {DataDisplayHelper.currency(
                                        applicationRevision.products[0].total_loan_amount,
                                        'kr'
                                    )}
                                </Table.Cell>
                                <Table.Cell>
                                    {ApplicationHelper.renderPeriod(applicationRevision.products[0].amortize_length)}
                                </Table.Cell>
                                <Table.Cell />

                                <Table.Cell>
                                    {this.props.responses.some((r) => r.revision === applicationRevision.revision) && (
                                        <span
                                            className={style.Link}
                                            onClick={this.toggleExpandedRevision(applicationRevision.revision)}
                                        >
                                            <Icon name={`chevron ${expanded ? 'up' : 'down'}`} />
                                            {expanded ? 'Hide' : 'Show'} responses
                                        </span>
                                    )}
                                </Table.Cell>
                            </Table.Row>,
                            ...props.responses
                                .filter(() => expanded)
                                .filter((r) => r.revision === applicationRevision.revision) // Only show responses connected to this applicationRevision (revision = revision number)
                                .sort((r1, r2) => moment(r2.created_at).diff(moment(r1.created_at)))
                                .map((r) => (
                                    <ResponseRow
                                        key={r.id}
                                        withdrawOfferCallback={this.props.withdrawOfferCallback}
                                        response={r}
                                        disableActions={this.props.disableActions}
                                    />
                                )),
                        ];
                    })}
                </Table.Body>
            </Table>
        );
    }
}

Revisions.propTypes = {
    revisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    responses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isBusiness: PropTypes.bool.isRequired,
    withdrawOfferCallback: PropTypes.func.isRequired,
    disableActions: PropTypes.bool.isRequired,
};
Revisions.defaultProps = {};

export default Revisions;
