import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

function ExplanationPopup(props) {
    return (
        <Popup
            on="hover"
            trigger={<Icon style={props.style} name="question circle" />}
            content={props.content || props.children}
        />
    );
}

ExplanationPopup.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    content: PropTypes.node,
    style: PropTypes.shape({}),
};
ExplanationPopup.defaultProps = {
    children: null,
    content: null,
    style: {},
};

export default ExplanationPopup;
