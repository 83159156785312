import ApplicationHelper from 'APP/helpers/ApplicationHelper';
import DataDisplayHelper from 'APP/helpers/DataDisplayHelper';
import DateHelper from 'APP/helpers/DateHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Label, Table } from 'semantic-ui-react';

function RevisionRow(props) {
    const { applicationRevision, hasResponses, isCurrentRevision, expanded, handleSubmit } = props;

    return (
        <Table.Row key={applicationRevision.revision} onClick={() => handleSubmit(applicationRevision.revision)}>
            <Table.Cell>
                <strong>Revision {applicationRevision.revision}</strong>
                {isCurrentRevision && <Label style={{ marginLeft: '1em' }}>Current</Label>}
            </Table.Cell>
            <Table.Cell>{DateHelper.formatDate(applicationRevision.created_at, 'YYYY-MM-DD')}</Table.Cell>
            <Table.Cell>
                {DataDisplayHelper.currency(applicationRevision.products[0].total_loan_amount, 'kr')}
            </Table.Cell>
            <Table.Cell>{ApplicationHelper.renderPeriod(applicationRevision.products[0].amortize_length)}</Table.Cell>
            <Table.Cell colSpan={2} textAlign="right">
                {hasResponses ? (
                    <span className="link">
                        <Icon name={`chevron ${expanded ? 'up' : 'down'}`} /> {expanded ? 'Hide' : 'Show'} responses
                    </span>
                ) : (
                    <span className="link disabled">No responses to revision</span>
                )}
            </Table.Cell>
        </Table.Row>
    );
}

RevisionRow.propTypes = {
    applicationRevision: PropTypes.shape({
        revision: PropTypes.number.isRequired,
        created_at: PropTypes.string.isRequired,
        products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    isCurrentRevision: PropTypes.bool.isRequired,
    hasResponses: PropTypes.bool.isRequired,
    expanded: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};
RevisionRow.defaultProps = {};

export default RevisionRow;
