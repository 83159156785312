import integrationModal from 'APP/reducers/integrationModalReducer';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import thunkMiddleware from 'redux-thunk';

import auth from '../reducers/auth-reducer';

const reducer = combineReducers({
    form: reduxFormReducer, // mounted under "form"
    integrationModal,
    auth,
});

const createStoreWithMiddleware = applyMiddleware(thunkMiddleware)(createStore);

const store = createStoreWithMiddleware(
    reducer,
    compose(
        process.env.NODE_ENV !== 'production' &&
            window.__REDUX_DEVTOOLS_EXTENSION__ &&
            typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function' &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);

export default store;
