import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const sentryKey = '8638d7788bf74c23a783ca9da3ca2d46';
const sentryApp = '1269243';

const url = `https://${sentryKey}@sentry.io/${sentryApp}`;

/**
 * Function will return the correct env depending on the window.location.origin value
 *
 * @returns {string} development, staging or production
 */
export function getEnv() {
    const originUrl = window.location.origin;
    const origin = originUrl.replace(/(^\w+:|^)\/\//, '');
    let env = 'development';

    switch (origin) {
        case 'admin.stage.lendo.se':
            env = 'staging';
            break;
        case 'admin.lendo.se':
            env = 'production';
            break;
        default:
            return env;
    }

    return env;
}

const config = {
    release: '%REACT_APP_RELEASE_VERSION%',
    environment: getEnv(),
    integrations: [new Integrations.BrowserTracing()],
};

/**
 * Will install Sentry with the declared url and config above.
 */
export function InitSentry() {
    Sentry.init({ dsn: url, ...config });
}
