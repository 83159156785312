import './style.css';

import DataDisplayHelper from 'APP/helpers/DataDisplayHelper';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Divider, Grid, Header, Icon, Segment, Table } from 'semantic-ui-react';

import { UpdatePropertyAnswersForm } from './UpdatePropertyAnswersForm';

function PropertyQuestions(props) {
    const { propertyQuestions, reFetchApplication, application, allowEdit = true } = props;

    // chunk property questions array in arrays of 2
    const chunk = (propertyList, size) =>
        propertyList.reduce(
            (previousValue, currentValue, currentIndex) => (
                currentIndex % size
                    ? previousValue[previousValue.length - 1].push(currentValue)
                    : previousValue.push([currentValue]),
                previousValue
            ),
            []
        );

    const { company } = application;

    const chunkQuestions = chunk(propertyQuestions, 2);

    return (
        <div>
            {propertyQuestions.length > 0 && (
                <div>
                    <Divider />
                    {chunkQuestions.map((propertyQuestionChunk, chunkIndex) => (
                        <Grid columns="equal" key={`property-${chunkIndex}-row`}>
                            {propertyQuestionChunk.map((propertyQuestion, propertyIndex) => (
                                <Grid.Column key={`property-${chunkIndex}-${propertyIndex}-column`}>
                                    <Property
                                        key={`property-${chunkIndex}-${propertyIndex}`}
                                        company={company}
                                        allowEdit={allowEdit}
                                        property={propertyQuestion}
                                        reFetchApplication={reFetchApplication}
                                    />
                                </Grid.Column>
                            ))}
                            {propertyQuestionChunk.length < 2 && <Grid.Column stretched />}
                        </Grid>
                    ))}
                </div>
            )}
        </div>
    );
}

function Property(props) {
    const { property, reFetchApplication, company, allowEdit } = props;

    const [editMode, setEditMode] = useState(false);

    let currentEstate;
    if (company.credit_data && company.credit_data.estates && company.credit_data.estates.length > 0) {
        company.credit_data.estates.forEach((estate) => {
            if (estate.property_label === property.property_name) {
                currentEstate = estate;
                return;
            }
        });
    }

    return (
        <Segment.Group className="basic">
            <Segment className="property-header">
                <Header as="h4">
                    {allowEdit && (
                        <div style={{ float: 'right' }}>
                            {!editMode ? (
                                <Button
                                    floated="right"
                                    size="small"
                                    icon="pencil"
                                    title="Edit property information"
                                    content="Edit"
                                    style={{ paddingLeft: '11px', paddingRight: '11px', height: '40px' }}
                                    onClick={() => setEditMode(true)}
                                />
                            ) : (
                                <Button
                                    floated="right"
                                    size="small"
                                    title="back"
                                    content="Back"
                                    style={{ marginRight: '10px' }}
                                    onClick={() => setEditMode(false)}
                                />
                            )}
                        </div>
                    )}
                    <Icon name="home" />
                    <Header.Content>
                        Property as security
                        <Header.Subheader>
                            {property.property_name
                                ? property.property_name
                                : `New property, ${property?.address || ''}, ${
                                      property?.postal_code || ''
                                  }, ${property?.municipality || ''}`}
                        </Header.Subheader>
                    </Header.Content>
                </Header>
            </Segment>
            {!editMode ? (
                <Segment className="property-header">
                    <Table structured compact basic="very">
                        <Table.Body>
                            {currentEstate && (
                                <Table.Row verticalAlign="top">
                                    <Table.Cell colSpan="2">
                                        <Header as="h4" sub content="Municipality" />
                                        <span>{currentEstate.municipality}</span>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            <Table.Row verticalAlign="top">
                                <Table.Cell>
                                    <Header as="h4" sub content="Presented market value" />
                                    <span>{DataDisplayHelper.currency(property.market_value, 'kr') || 'N/A'}</span>
                                </Table.Cell>
                                <Table.Cell>
                                    <Header as="h4" sub content="Approximately existing loan" />
                                    <span>{DataDisplayHelper.currency(property.loan_amount, 'kr') || 'N/A'}</span>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Segment>
            ) : (
                <UpdatePropertyAnswersForm reFetchApplication={reFetchApplication} property={property} />
            )}
        </Segment.Group>
    );
}

PropertyQuestions.propTypes = {
    propertyQuestions: PropTypes.array.isRequired,
    reFetchApplication: PropTypes.func,
    application: PropTypes.object.isRequired,
};

export default PropertyQuestions;
