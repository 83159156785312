import { captureException } from '@sentry/react';
import Toast from 'APP/common/Toast/';

import { getEnv } from '../sentry';

/**
 * Handle an error by sending it to sentry and present the error to the user
 * @param {Error} error Error
 * @param {string} [contextDescription=""] A description of what the frontend tried to do when the error occurred
 */
export function HandleException(error, contextDescription) {
    // Sends error to sentry and logs error if in development.
    try {
        captureException(error);
        const env = getEnv();
        if (env !== 'production') {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    const errorMessage = {
        description: contextDescription,
        message: error?.message,

        // Properties related to XHR request/response errors.
        correlationId: error?.correlationId,
        statusCode: error?.statusCode,
        responseUrl: error?.responseUrl,
    };
    // Toast the error
    Toast('error', errorMessage);
}
