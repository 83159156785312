import PropTypes from 'prop-types';
import React from 'react';

import BusinessRates from './BusinessRates';
import PrivateRates from './PrivateRates';

function Rates(props) {
    if (props.isBusiness) {
        return <BusinessRates />;
    }

    return <PrivateRates />;
}

Rates.propTypes = {
    isBusiness: PropTypes.bool.isRequired,
};
Rates.defaultProps = {};

export default Rates;
