import PropTypes from 'prop-types';
import React from 'react';
import { Header, Segment, Table } from 'semantic-ui-react';

import { AccomodationOptions } from '../../../../enums/Applicant/Accommodation';
import { CivilStateOptions } from '../../../../enums/Applicant/CivilState';
import { Options as OccupationTypes } from '../../../../enums/Applicant/Occupation';
import ApplicationHelper from '../../../../helpers/ApplicationHelper';
import Address from './Address';
import ApplicantCardHeader from './Header';

const renderIncome = (incomes) => {
    for (const i in incomes) {
        if (incomes.hasOwnProperty(i)) {
            return ApplicationHelper.renderAmount(incomes[i].taxable_income);
        }
    }
};

const ApplicantCardPrivate = ({ applicant, type }) => {
    const occupation =
        applicant.employment && applicant.employment.type
            ? OccupationTypes.find((type) => type.value === applicant.employment.type)
            : '';
    const employment = applicant.employment;
    const creditData = applicant.credit_data;
    const formatWorkedSince = (month) => (month < 10 ? `0${month}` : month);
    return (
        <Segment.Group className="basic">
            <ApplicantCardHeader name={applicant.full_name} ssn={applicant.ssn} type={type} />
            <Segment>
                <Table basic="very" selectable compact>
                    <Table.Body>
                        <Table.Row verticalAlign="top">
                            <Table.Cell>
                                <Header as="h4" sub content="SSN" />
                                <span>{ApplicationHelper.formatSSN(applicant.ssn)}</span>
                            </Table.Cell>
                            <Table.Cell>
                                <Header as="h4" sub content="Phone" />
                                <span>{applicant.phone && applicant.phone}</span>
                            </Table.Cell>
                            <Table.Cell>
                                <Header as="h4" sub content="Email" />
                                <span>{applicant.email && applicant.email}</span>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row verticalAlign="top">
                            <Table.Cell>
                                <Header as="h4" sub content="Occupation" />
                                {occupation && occupation.text}
                            </Table.Cell>
                            <Table.Cell>
                                <Header as="h4" sub content="Employer" />
                                {employment && employment.company.name ? employment.company.name : ''}
                            </Table.Cell>
                            <Table.Cell>
                                <Header as="h4" sub content="Employed since" />
                                {employment &&
                                    employment.since &&
                                    `${employment.since.year} - ${formatWorkedSince(employment.since.month)}`}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row verticalAlign="top">
                            <Table.Cell>
                                <Header as="h4" sub content="Employment end date" />
                                {employment &&
                                    employment.to &&
                                    employment.to.year &&
                                    employment.to.month &&
                                    `${employment.to.year} - ${formatWorkedSince(employment.to.month)}`}
                            </Table.Cell>
                            <Table.Cell>
                                <Header as="h4" sub content="Monthly income" />
                                {ApplicationHelper.renderAmount(applicant.monthly_income)}
                            </Table.Cell>
                            <Table.Cell>
                                <Header as="h4" sub content="Taxable income" />
                                {creditData && creditData.incomes ? renderIncome(creditData.incomes) : 'Not available'}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row verticalAlign="top">
                            <Table.Cell>
                                <Header as="h4" sub content="Phone (employer)" />
                                {employment && employment.company.phone && employment.company.phone}
                            </Table.Cell>
                            <Table.Cell>
                                <Header as="h4" sub content="Children in household" />
                                {applicant.number_of_children}
                            </Table.Cell>
                            <Table.Cell>
                                <Header as="h4" sub content="Civil state" />
                                {CivilStateOptions[applicant.civil_state]}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row verticalAlign="top">
                            <Table.Cell>
                                <Header as="h4" sub content="Accomodation" />
                                {applicant.accommodation && AccomodationOptions[applicant.accommodation.type]}
                            </Table.Cell>
                            <Table.Cell>
                                <Header as="h4" sub content="Accomodation costs" />
                                {applicant.accommodation && applicant.accommodation.monthly_cost} kr
                            </Table.Cell>
                            <Table.Cell>
                                <Header as="h4" sub content="Address" />
                                {applicant.personal_info && applicant.personal_info.address && (
                                    <Address {...applicant.personal_info.address} />
                                )}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
        </Segment.Group>
    );
};

ApplicantCardPrivate.propTypes = {
    applicant: PropTypes.object.isRequired,
    type: PropTypes.string,
};

ApplicantCardPrivate.defaultProps = {
    type: 'main',
};

export default ApplicantCardPrivate;
