export default {
    CASH_FLOW: 'cash_flow',
    INVESTMENT_SEASON: 'investment_season',
    PURCHASE_INVENTORY: 'purchase_inventory',
    PURCHASE_EQUIPMENT: 'purchase_equipment',
    REFURBISHMENT: 'refurbishment',
    MARKETING: 'marketing',
    PERMIT_APPLICATION: 'permit_application',
    UNEXPECTED_EXPENSES: 'unexpected_expenses',
    EXPANSION: 'expansion',
    FINANCE_DEBT: 'finance_debt',
    HIRING: 'hiring',
    ACQUISITION: 'acquisition',
    PROPERTY_ACQUISITION: 'property_acquisition',
    OTHER: 'other',
};
