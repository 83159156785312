import ProductHelper from 'APP/helpers/ProductHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { Header, Table } from 'semantic-ui-react';

function LoanPurpose(props) {
    if (!props.loanPurpose) {
        return null;
    }

    return (
        <Table.Cell>
            <Header as="h4" sub content="Loan purpose" />
            <span>{ProductHelper.getLoanPurposeText(props.loanPurpose, props.product)}</span>
        </Table.Cell>
    );
}

LoanPurpose.propTypes = {
    product: PropTypes.shape({
        category: PropTypes.string.isRequired,
    }),
    loanPurpose: PropTypes.string,
};

LoanPurpose.defaultProps = {
    product: null,
    loanPurpose: null,
};

export default LoanPurpose;
