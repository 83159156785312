import { getInsuranceSearchParam } from 'APP/helpers/ActionsHelpers';

import config from '../config';
import AjaxHelper from '../helpers/AjaxHelper';

const baseUrl = `${config.api.prefix}/insurances`;

export default {
    get: (id) => AjaxHelper.get(`${baseUrl}/${id}`, 'data'),
    getFormInsurance: (id) => AjaxHelper.get(`${baseUrl}/safety-insurance-form/${id}`, 'data'),
    getAcceptances: (insuranceId) => AjaxHelper.get(`${baseUrl}/${insuranceId}/acceptances`, 'data.acceptances'),
    search: (search) => AjaxHelper.get(`${baseUrl}?search=${encodeURIComponent(search)}`, 'data'),
    searchInsuranceSeparateProduct: (search) => {
        const searchParam = getInsuranceSearchParam(search);
        if (searchParam) {
            return AjaxHelper.get(`${baseUrl}/safety-insurance-form?${searchParam}`, 'data');
        }
    },
    patchInsurance: (id, body) => AjaxHelper.patch(`${baseUrl}/safety-insurance-form/${id}`, body),
};
