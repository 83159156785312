import ComplementsList from 'APP/components/Application/common/ComplementsList';
import UploadedFiles from 'APP/components/Application/common/UploadedFiles';
import style from 'APP/components/Application/common/UploadedFiles/style.css';
import ResponseHelper from 'APP/helpers/ResponseHelper';
import useApplicationFiles from 'APP/hooks/useUploadedFiles';
import PropTypes from 'prop-types';
import React from 'react';
import { Divider, Grid, Header } from 'semantic-ui-react';

import AdditionalInformationAcceptedOffer from './AdditionalInformationAcceptedOffer';
import BusinessAcceptedOffer from './BusinessAcceptedOffer';
import PrivateAcceptedOffer from './PrivateAcceptedOffer';

const AcceptedOffer = (props) => {
    const isBusiness = ResponseHelper.isBusinessLoanOffer(props.offer);
    const uploadedFiles = useApplicationFiles(props.offer);

    return (
        <div>
            <Header content="Accepted offer" />
            <Grid>
                <Grid.Row>
                    <Grid.Column width={12}>
                        {isBusiness ? (
                            <BusinessAcceptedOffer offer={props.offer} />
                        ) : (
                            <PrivateAcceptedOffer offer={props.offer} />
                        )}
                        <Divider />
                        <AdditionalInformationAcceptedOffer
                            product={props.product}
                            application={props.application}
                            acceptance={props.acceptance}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Header content="Requested complements" />
                        <ComplementsList complements={props.offer.complements} />
                        {isBusiness && uploadedFiles && uploadedFiles.length > 0 && (
                            <UploadedFiles files={uploadedFiles} className={style.partnerUploadedFiles} />
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};

AcceptedOffer.propTypes = {
    offer: PropTypes.shape({}).isRequired,
    application: PropTypes.shape({
        products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    product: PropTypes.shape({}).isRequired,
    acceptance: PropTypes.shape({}).isRequired,
};

export default AcceptedOffer;
