import React from 'react';
import { Icon, Popup, Table } from 'semantic-ui-react';

const headers = [
    'Created at',
    'Application',
    <Popup
        key="company_registration"
        trigger={
            <span>
                ID <Icon name="question circle" />
            </span>
        }
    >
        For business loans it will show the company&#39;s registration number. For private loans it will show the main
        applicant&#39;s SSN
    </Popup>,
    'Total loan amount',
    'Amortize length',
    'Product',
    'View',
];

function Header() {
    return (
        <Table.Row>
            {headers.map((header) => (
                <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
            ))}
        </Table.Row>
    );
}

Header.propTypes = {};
Header.defaultProps = {};

export default Header;
