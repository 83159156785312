import config from '../config';
import AjaxHelper from '../helpers/AjaxHelper';

const baseUrl = `${config.api.prefix}/bankid`;
const signatoryUrl = `${config.api.prefix}/credit/authorized-signatory-list`;

/**
 * The return data from calling postSSN
 * @typedef {Object} PostSSNResponse
 * @property {string} reference
 * @property {string} autostartToken
 * @property {string} status
 * @property {string} message
 */

/**
 * The return data from calling pollBankIDStatus
 * @typedef {Object} PollBankIDStatusResponse
 * @property {string} reference
 * @property {string} progress
 * @property {string} id
 * @property {string} personal_number
 */

export default {
    /**
     * Posts the given ssn to the backend and returns
     * a reference id need for future polling.
     * @param {string} ssn
     * @returns {Promise<PostSSNResponse>}
     */
    postSSN(ssn, call_initiator) {
        return AjaxHelper.post(`${baseUrl}/phone/auth`, { ssn, call_initiator }, 'data');
    },

    /**
     * Polls for the given reference id. Resolves if the return status is ok,
     * otherwise throws.
     * @param {PollBankIDStatusResponse} reference
     */
    pollBankIDStatus(reference) {
        return AjaxHelper.get(`${baseUrl}/collect/${reference}`, 'data');
    },

    // Cancel the BankID challenge so we dont have a open session remaining.
    cancel(reference) {
        return AjaxHelper.get(`${baseUrl}/cancel/${reference}`);
    },

    signatoryList(ssn) {
        return AjaxHelper.post(`${signatoryUrl}`, { data: { ssn } });
    },

    /**
     * Updates the BankID challenge reference on the applicant.
     * @param {PollBankIDStatusResponse} reference
     */
    updateApplicantsBankIdReference(applicantId, reference) {
        const data = { bank_id_challenge_reference: reference };
        return AjaxHelper.patch(`${config.api.prefix}/applicants/mortgage/${applicantId}/bankid`, { data }, 'data');
    },
};
