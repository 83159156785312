import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

function AssumedPopup() {
    return (
        <Popup
            content="The applicant has assumed that this person is the benificial owner"
            trigger={<Icon name="warning sign" />}
        />
    );
}

AssumedPopup.propTypes = {};
AssumedPopup.defaultProps = {};

export default AssumedPopup;
