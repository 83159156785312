import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

function ViewApplicationButton(props) {
    const location = {
        pathname: `${props.baseURL}/${props.applicationId}`,
        search: props.productId ? `product_id=${props.productId}` : '',
        state: {
            listQueryParams: queryString.parse(window.location.search),
        },
    };

    return <Button as={Link} to={location} content="View" primary style={{ padding: '10px 20px' }} />;
}

ViewApplicationButton.propTypes = {
    baseURL: PropTypes.string.isRequired,
    applicationId: PropTypes.string.isRequired,
    productId: PropTypes.string,
};
ViewApplicationButton.defaultProps = {
    productId: null,
};

export default ViewApplicationButton;
