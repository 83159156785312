const Enum = {
    LOAN_COLLECT: 'loan_collect',
    VEHICLE: 'vehicle',
    HOME_IMPROVEMENT: 'home_improvement',
    HEALTH_VACATION: 'health_vacation',
    OTHER: 'other',
};

export const Options = [
    { value: Enum.LOAN_COLLECT, text: 'Pay off loans/credits...' },
    { value: Enum.VEHICLE, text: 'Buy vehicle' },
    { value: Enum.HOME_IMPROVEMENT, text: 'House and home' },
    { value: Enum.HEALTH_VACATION, text: 'Vacation, Wellness' },
    { value: Enum.OTHER, text: 'Other' },
];

export default Enum;
