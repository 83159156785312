export const RevisionChanges = {
    amortize_length: 'Amortize length',
    amount_to_collect: 'Amount to collect',
    total_loan_amount: 'Total loan amount',
    number_of_children: 'Number of children',
    'employment.company.name': 'Company name',
    'employment.monthly_income': 'Monthly income',
    'employment.type': 'Employment form',
    'accommodation.monthly_cost': 'Monthly accommodation cost',
    'accommodation.type': 'Accommodation type',
    number_of_applicants: 'Number of applicants',
    civil_state: 'Civil state',
};
