import BankIDActions from 'APP/actions/BankIDActions';
import BankIdConstants from 'APP/consts/BankIdConstants';
import { createEffect } from 'APP/helpers/Effect';
import { useEffect, useReducer } from 'react';

function reducer(state, event) {
    if (event?.type === 'CANCEL_AND_CLOSE') {
        return {
            ...state,
            effects: [...state.effects, createEffect('cancel', { reference: state.bankIdReference })],
        };
    }

    if (event?.type === 'CANCELLED') {
        return initialState;
    }

    switch (state.status) {
        case 'idle': {
            if (event.type === 'TRIGGER_BANK_ID_VERIFICATION') {
                return {
                    ...state,
                    call_initiator: event.whoInitiated,
                    status: 'pending_ssn',
                    effects: [...state.effects, createEffect('postSSN')],
                };
            }

            return state;
        }
        case 'pending_ssn': {
            if (event.type === 'RESOLVE') {
                return {
                    ...state,
                    bankIdReference: event.reference,
                    status: 'pending_polling',
                    effects: [...state.effects, createEffect('pollBankId', { reference: event.reference })],
                };
            }

            if (event.type === 'REJECT') {
                return {
                    ...state,
                    status: 'rejected',
                };
            }

            return state;
        }
        case 'pending_polling': {
            if (event.type === 'RESOLVE') {
                if (event.progress === BankIdConstants.PROGRESS.COMPLETE) {
                    sessionStorage.setItem('bankIdVerification', true);
                    return {
                        ...state,
                        bankIdStatus: BankIdConstants.PROGRESS.COMPLETE,
                        status: 'resolved',
                    };
                }

                if (event.progress === BankIdConstants.PROGRESS.USER_CANCEL) {
                    return {
                        ...state,
                        bankIdStatus: BankIdConstants.PROGRESS.USER_CANCEL,
                        status: 'rejected',
                    };
                }

                if (event.progress === BankIdConstants.PROGRESS.NO_USER) {
                    return {
                        ...state,
                        bankIdStatus: BankIdConstants.PROGRESS.NO_USER,
                        status: 'rejected',
                    };
                }

                if (event.progress === BankIdConstants.PROGRESS.NO_CLIENT) {
                    return {
                        ...state,
                        bankIdStatus: BankIdConstants.PROGRESS.NO_CLIENT,
                        status: 'rejected',
                    };
                }

                return {
                    ...state,
                    bankIdStatus: event.progress,
                    status: 'awaiting_polling',
                    effects: [...state.effects, createEffect('awaitPoll')],
                };
            }

            if (event.type === 'REJECT') {
                return {
                    ...state,
                    bankIdStatus: BankIdConstants.PROGRESS.UNKNOWN_ERROR,
                    status: 'rejected',
                };
            }

            return state;
        }
        case 'awaiting_polling': {
            if (event.type === 'RESOLVE') {
                return {
                    ...state,
                    status: 'pending_polling',
                    effects: [...state.effects, createEffect('pollBankId', { reference: state.bankIdReference })],
                };
            }

            return state;
        }
        case 'resolved': {
            return state;
        }
        case 'rejected': {
            if (event.type === 'TRIGGER_BANK_ID_VERIFICATION') {
                return {
                    ...state,
                    status: 'pending_ssn',
                    bankIdStatus: BankIdConstants.PROGRESS.RESUME_POLLING,
                    effects: [...state.effects, createEffect('postSSN', { ssn: state['applicant.main.ssn'] })],
                };
            }

            return state;
        }

        default: {
            return state;
        }
    }
}

const initialState = {
    status: 'idle',
    effects: [],
    bankIdReference: '',
    bankIdStatus: BankIdConstants.PROGRESS.RESUME_POLLING,
};

export function useBankIdVerificationMachine(options) {
    const [{ effects, ...state }, dispatch] = useReducer(reducer, initialState);
    const { applicant } = options;

    useEffect(() => {
        for (const effect of effects) {
            if (effect.status !== 'idle') {
                continue;
            }
            effect.markAsStarted();

            if (effect.type === 'postSSN') {
                BankIDActions.postSSN(applicant.ssn, state.call_initiator)
                    .then((res) => dispatch({ type: 'RESOLVE', reference: res.reference }))
                    .catch(() => dispatch({ type: 'REJECT' }));
            }

            if (effect.type === 'pollBankId') {
                BankIDActions.pollBankIDStatus(state.bankIdReference)
                    .then((res) => dispatch({ type: 'RESOLVE', progress: res.progress, reference: res.reference }))
                    .catch(() => dispatch({ type: 'REJECT' }));
            }

            if (effect.type === 'awaitPoll') {
                setTimeout(() => dispatch({ type: 'RESOLVE' }), BankIdConstants.WAIT_TIME);
            }

            if (effect.type === 'cancel') {
                if (['awaiting_polling', 'pending_polling', 'pending_ssn'].includes(state.status)) {
                    BankIDActions.cancel(state.bankIdReference);
                }

                dispatch({ type: 'CANCELLED' });
            }
        }
    }, [state, effects, applicant]);

    return [state, dispatch];
}
