import { Box, Input, useOnClickOutside } from '@lendoab/aphrodite';
import { createUUID } from '@lendoab/lendose-shared';
import AddressActions from 'APP/actions/AddressActions';
import PoweredByGoogleImage from 'APP/assets/misc_logos/poweredByGoogle.png';
import { classNames } from 'APP/helpers/css';
import { debounce } from 'APP/helpers/debounce';
import { createEffect } from 'APP/helpers/Effect';
import { isNullOrUndefined } from 'APP/helpers/ValidationHelper';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useReducer, useRef } from 'react';

import AddressSelectMenu from './AddressSelectMenu';
import AddressSelectMenuItem from './AddressSelectMenuItem';
import style from './style.css';

const sessionId = createUUID();

const MIN_CHARACTER = 3;

const KEY_CODES = {
    ENTER_KEY_CODE: 13,
    DOWN_ARROW_KEY_CODE: 40,
    UP_ARROW_KEY_CODE: 38,
    ESCAPE_KEY_CODE: 27,
};

function reducer(state, event) {
    switch (state.status) {
        case 'server.search':
            if (event.type === 'ON_CHANGE') {
                return {
                    ...state,
                    isLoading: true,
                    options: [],
                    effects: [...state.effects, createEffect('fetchPlaces', { value: event.value })],
                };
            }

            if (event.type === 'SET_PLACES') {
                return {
                    ...state,
                    isLoading: false,
                    isMenuOpen: event.options?.length > 0,
                    options: event.options,
                };
            }

            if (event.type === 'GET_PLACES_DETAILS') {
                return {
                    ...state,
                    effects: [...state.effects, createEffect('getPlacesDetails', { placeId: event.option?.value })],
                };
            }

            if (event.type === 'CLOSE_MENU') {
                return {
                    ...state,
                    isLoading: false,
                    isMenuOpen: false,
                };
            }

            if (event.type === 'REJECT_SERVER_SEARCH') {
                return {
                    ...state,
                    isMenuOpen: false,
                    isLoading: false,
                    status: 'client.input',
                    effects: [...state.effects, createEffect('captureError', { error: event.error })], // not yet implemented
                };
            }

            return state;

        case 'client.input':
            return state;

        default:
            return state;
    }
}

export default function AddressAutoCompleteInput(props) {
    const { onChange, onAddressSelected, error, success, warning, ...rest } = props;

    const containerRef = useRef(null);
    const inputRef = useRef(null);
    const ulElementRef = useRef(null);

    useOnClickOutside(containerRef, onClickOutside);

    const getPlacesPredictions = useCallback(
        debounce((value) => {
            AddressActions.getPlacesPredictions(value, sessionId)
                .then(({ data }) => {
                    if (data && data.data.length > 0) {
                        const options = data.data.map((prediction) => ({
                            value: prediction.id,
                            label: prediction.description,
                        }));

                        dispatch({ type: 'SET_PLACES', options });
                    } else {
                        dispatch({ type: 'CLOSE_MENU' });
                    }
                })
                .catch((err) => {
                    dispatch({ type: 'REJECT_SERVER_SEARCH', err });
                });
        }),
        []
    );

    const [{ effects, ...state }, dispatch] = useReducer(reducer, {
        status: 'server.search',
        isMenuOpen: false,
        isLoading: false,
        options: [],
        effects: [],
    });

    useEffect(() => {
        for (const effect of effects) {
            if (effect.status !== 'idle') {
                continue;
            }
            effect.markAsStarted();

            if (effect.type === 'fetchPlaces') {
                getPlacesPredictions(effect.value);
            }

            if (effect.type === 'getPlacesDetails') {
                AddressActions.getPlacesDetails(effect.placeId, sessionId)
                    .then(({ data }) => {
                        typeof onAddressSelected === 'function' && onAddressSelected(data);
                        dispatch({ type: 'CLOSE_MENU' });
                    })
                    .catch((err) => {
                        dispatch({ type: 'REJECT_SERVER_SEARCH', err });
                    });
            }
        }
    }, [state, onAddressSelected, getPlacesPredictions, effects]);

    const containerClassNames = classNames(
        style.select,
        state.isMenuOpen && style.open,

        success && style.successOutline,
        Boolean(warning) && state.isMenuOpen && style.warningOutline,
        Boolean(error) && state.isMenuOpen && style.errorOutline
    );

    function onInputChange(event) {
        const value = event.target.value;

        typeof onChange === 'function' && onChange(event);

        if (!isNullOrUndefined(value) && value.length >= MIN_CHARACTER) {
            dispatch({ type: 'ON_CHANGE', value });
        } else {
            dispatch({ type: 'CLOSE_MENU' });
        }
    }

    function onPlaceClicked(option) {
        dispatch({ type: 'GET_PLACES_DETAILS', option });
    }

    function onClickOutside() {
        if (state.isMenuOpen) {
            dispatch({ type: 'CLOSE_MENU' });
        }
    }

    function handleKeyDown(event, option) {
        switch (event.keyCode) {
            case KEY_CODES.DOWN_ARROW_KEY_CODE:
                event.preventDefault();
                event.stopPropagation();

                const nextLi = getNextLi(event.target);

                if (nextLi) {
                    nextLi.focus();
                } else {
                    const firstLi = getFirstLi();
                    if (firstLi) {
                        firstLi.focus();
                    } else {
                        inputRef.current && inputRef.current.focus();
                    }
                }
                break;

            case KEY_CODES.UP_ARROW_KEY_CODE:
                event.preventDefault();
                event.stopPropagation();

                const previousLi = getPreviousLi(event.target);

                if (previousLi) {
                    previousLi.focus();
                } else {
                    inputRef.current && inputRef.current.focus();
                }
                break;

            case KEY_CODES.ENTER_KEY_CODE:
                if (option?.value) {
                    onPlaceClicked(option);
                }
                break;

            case KEY_CODES.ESCAPE_KEY_CODE:
                dispatch({ type: 'CLOSE_MENU' });
                break;

            default:
                break;
        }

        function getFirstLi() {
            return containerRef.current?.getElementsByTagName('li')?.[0];
        }

        function getNextLi(element) {
            if (element.nextElementSibling?.nodeName === 'LI') {
                return element.nextElementSibling;
            }

            return undefined;
        }

        function getPreviousLi(element) {
            if (element.previousElementSibling && element.previousElementSibling.nodeName === 'LI') {
                return element.previousElementSibling;
            }

            return undefined;
        }
    }

    return (
        <Box className={containerClassNames} ref={containerRef}>
            <Input
                ref={inputRef}
                type="text"
                onChange={onInputChange}
                onKeyDown={handleKeyDown}
                loading={state.isLoading}
                autoComplete="off"
                className={style.input}
                error={error}
                success={success}
                warning={warning}
                {...rest}
            />
            {state.isMenuOpen && (
                <AddressSelectMenu>
                    <Box className={style.selectMenuScrollableArea} ref={ulElementRef} role="listbox" component="ul">
                        {/* <Divider /> */}
                        {state.options.map((option) => (
                            <AddressSelectMenuItem
                                key={option.value}
                                value={option.value}
                                label={option.label}
                                onClick={() => onPlaceClicked(option)}
                                onKeyDown={(event) => handleKeyDown(event, option)}
                            />
                        ))}
                    </Box>
                    <Box display="flex" alignItems="center" paddingX="medium" className={style.poweredByGoogleFooter}>
                        <img src={PoweredByGoogleImage} alt="powered by google" loading="lazy" />
                    </Box>
                </AddressSelectMenu>
            )}
        </Box>
    );
}

AddressAutoCompleteInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    onAddressSelected: PropTypes.func.isRequired,

    success: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    warning: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};
