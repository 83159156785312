export default {
    // APPLICATION
    APPLICATION_LIST: 'application.list',
    APPLICATION_MANAGE: 'application.manage',
    APPLICATION_LOCK_MANAGE: 'application.lock.manage',
    // loan offer paid
    WITHDRAW_LOAN_OFFER_PAID: 'loan-offer-paid.withdraw',
    // Mortgage
    MORTGAGE_APPLICATION_MANAGE: 'mortgage-application.manage',

    // Mortgage ownership
    MORTGAGE_OWNERSHIPS_LIST: 'mortgage-ownerships.list',
    MORTGAGE_OWNERSHIPS_MANAGE: 'mortgage-ownerships.manage',

    // Mortgage notifications
    MORTGAGE_NOTIFICATIONS_BASIC: 'notifications.basic',
    MORTGAGE_NOTIFICATIONS_MANAGE: 'notifications.manage',

    // User
    ADMIN_USER_MANAGE: 'admin-user.manage', // Create/edit a user
    ADMIN_USER_LIST: 'admin-user.list', // List users
    IMPERSONATE_CUSTOMER_USER: 'impersonate.customer_user',
    IMPERSONATE_PARTNER_USER: 'impersonate.partner_user',
    // Bank ID
    BANK_ID_REMOTE_AUTHING: 'bank-id.remote-authing',
    // Score
    SCORE_VIEW: 'score.view', // View your own score
    SCORE_MANAGE: 'score.manage', // Manage score by assigning points on requests
    // Partner
    PARTNER_LIST: 'partner.list',
    PARTNER_ADMIN: 'partner-admin.users.manage', // Manage users and roles
    // Products
    PRODUCT_LIST: 'product.list',
    // Queue
    MORTGAGE_QUEUE_MANAGE: 'mortgage-queue.manage', // Manage mortgage queues
    QUEUE_MANAGE: 'queue.manage', // Manage queues
    MORTGAGE_QUEUE_NEXT: 'mortgage-queue.next', // List the mortgage queues
    QUEUE_NEXT: 'queue.next', // List the queues
    // Messages
    MESSAGES_CREATE: 'messages.create',
    // Customer
    CUSTOMER_GET: 'customer.get',
    // Templates
    MESSAGE_TEMPLATE_MANAGE: 'message-template.manage',
    // Statistics
    STATISTICS_LIST: 'statistics.list',
    // Reports
    REPORTS_LIST: 'reports.list',
    // Campaigns
    CAMPAIGN_GET: 'campaign.get',
    // SCHEDULER
    SCHEDULER_DEAD_MANAGE: 'scheduler.dead.manage',
    SCHEDULER_DEAD_LIST: 'scheduler.dead.list',
    SCHEDULER_LIST_SCHEDULED_EVENTS: 'scheduler.jobs.list',
    // Consent - Blacklist
    CONSENT_BLACKLIST: 'application.manage',
    // Integration
    INTEGRATION_TASK_MANAGE: 'integration-task.manage',
    // Deletion
    DATA_DELETION_REQUEST_CREATE: 'data-deletion-request.create',
    // Product filtering
    FILTERED_PRODUCT_MANAGE: 'filtered-product.manage',
    // change credit date expiry
    CHANGE_CREDIT_EXPIRY_DATE: 'application.change-credit-expiry-date',
    // reactive a withdrawn offer
    LOAN_OFFER_WITHDRAWN_REACTIVATE: 'loan-offer-withdrawn.reactivate',
    // Manage loan offer expirations
    LOAN_OFFER_EXPIRATION_MANAGE: 'loan-offer-expiration.manage',
    // View fraud-reports
    FRAUD_REPORT_VIEW: 'fraud-report.view',
};
