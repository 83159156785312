import { validateJWT } from '../../actions/auth-actions-redux';
import authConfig from '../../config/auth';

class TokenOnUserActive {
    constructor() {
        this.dispatch = null;
        // Whether the user is active or not
        this.activeState = false;
        // Listen for DOM events to set user active
        document.addEventListener('onmousedown', this.setUserActive);
        document.addEventListener('onkeypress', this.setUserActive);
    }

    init = (dispatcher) => {
        this.inactiveTimer = null;
        this.checkTimer = null;
        this.dispatch = dispatcher;
        this.checkTimer = setInterval(this.checkActive, authConfig.updateToken.checkIntervall);
        this.setUserActive();
    };

    setUserActive = () => {
        this.activeState = true;
        if (this.inactiveTimer) {
            clearTimeout(this.inactiveTimer);
        }
        // Set timer that will set the user to inactive, unless func is triggered again
        this.inactiveTimer = setTimeout(() => {
            this.activeState = false;
        }, authConfig.updateToken.inactiveAfter);
    };

    checkActive = () => {
        // If user inactive, do nothing
        if (!this.activeState) {
            return;
        }
        // If user active, refresh token
        this.dispatch(validateJWT(true));
    };
}
const TOK = new TokenOnUserActive();
export default TOK;
