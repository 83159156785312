const categories = {
    PRIVATE_LOAN: 'privateloan',
    BUSINESS_LOAN: 'businessloan',
    MORTGAGE_LOAN: 'mortgageloan',
    LOAN_INSURANCE: 'loaninsurance',
    SAFETY_INSURANCE: 'safetyinsurance',
    ACCIDENT_INSURANCE: 'accidentinsurance',
    CREDIT_CARD: 'creditcard',
};

export default categories;
