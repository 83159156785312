import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Input, Label } from 'semantic-ui-react';

import ExplanationPopup from '../../common/ExplanationPopup';
import style from './SearchBarPartner.css';

class SearchBarPartner extends Component {
    MIN_INPUT = 10; // This is the minimum length of our search (a registration number w/o dashes)

    INITIAL_STATE = {
        input: '',
        error: false, // True or false for too small inputs
    };

    state = this.INITIAL_STATE;

    handleChange = (e, { value }) => this.setState({ input: value, error: false });

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.submit();
        }
    };

    submit = () => {
        if (this.state.input.length < this.MIN_INPUT) {
            this.setState({ error: true });
            return;
        }

        const searchString = qs.stringify({ query: this.state.input });

        this.props.history.push(`/application-search?${searchString}`);

        this.setState(this.INITIAL_STATE);
    };

    render() {
        return (
            <div>
                {this.state.error && (
                    <Label pointing="right" color="red">
                        Too few characters{' '}
                        <ExplanationPopup content="You can only search for application IDs, registration numbers or SSNs" />
                    </Label>
                )}
                <Input
                    error={this.state.error}
                    className={`${style.Search} ${this.state.input.length >= this.MIN_INPUT ? 'enabled' : ''}`}
                    placeholder="Search for specific application..."
                    action={{ icon: 'search', onClick: this.submit }}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    value={this.state.input}
                />
            </div>
        );
    }
}

SearchBarPartner.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};
SearchBarPartner.defaultProps = {};

export default withRouter(SearchBarPartner);
