import { Box } from '@lendoab/aphrodite';
import PropTypes from 'prop-types';
import React from 'react';

import style from './style.css';

export default function AddressSelectMenu(props) {
    const { children, ...rest } = props;

    return (
        <Box
            backgroundColor="white"
            fluid
            component="div"
            padding="none"
            borderTopLeftRadius="none"
            borderTopRightRadius="none"
            borderBottomLeftRadius="base"
            borderBottomRightRadius="base"
            className={style.selectMenu}
            {...rest}
        >
            {children}
        </Box>
    );
}

AddressSelectMenu.propTypes = {
    children: PropTypes.any,
};
