import ResponseStatuses from 'APP/enums/Response/Statuses';
import PropTypes from 'prop-types';
import React from 'react';
import { Header } from 'semantic-ui-react';

function ResponseLabel(props) {
    const { response } = props;

    let privateResponse;
    let color = 'grey';
    if (response.type === 'loan_offer') {
        if (response.status === ResponseStatuses.WITHDRAWN) {
            privateResponse = 'Withdrawn';
        } else if (response.status === ResponseStatuses.DRAFT) {
            privateResponse = 'Draft';
        } else {
            privateResponse = 'Active loan offer';
            color = 'green';
        }
    } else {
        privateResponse = response.type === 'denial' ? 'Denied' : 'Request co-applicant';
        color = 'red';
    }

    return (
        <Header color={color} sub>
            {privateResponse}
        </Header>
    );
}

ResponseLabel.propTypes = {
    response: PropTypes.object.isRequired,
};
ResponseLabel.defaultProps = {};

export default ResponseLabel;
