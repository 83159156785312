export const Enum = {
    SINGLE: 'single',
    LIVING_TOGETHER: 'live_together',
    MARRIED: 'married',
    DIVORCED: 'divorced',
};

export const Options = [
    { value: Enum.SINGLE, text: 'Single' },
    { value: Enum.LIVING_TOGETHER, text: 'Partner' },
    { value: Enum.MARRIED, text: 'Married' },
    { value: Enum.DIVORCED, text: 'Divorced' },
];

export const CivilStateOptions = {
    single: 'Single',
    live_together: 'Live together',
    married: 'Married',
    divorced: 'Divorced',
};
