export const UserTypes = {
    ADMIN: 'admin_user',
    PARTNER: 'partner_user',
    PARTNERAPI: 'partnerapi_user',
    CUSTOMER: 'customer_user',
};

export const UserTypeNames = {
    [UserTypes.ADMIN]: 'Admin',
    [UserTypes.PARTNER]: 'Partner user',
    [UserTypes.PARTNERAPI]: 'Partner API',
    [UserTypes.CUSTOMER]: 'Customer',
};
