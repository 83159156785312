import config from '../config';
import AjaxHelper from '../helpers/AjaxHelper';
import store from '../stores/ReduxStore';

const baseUrl = `${config.api.prefix}/call-queues`;

export default {
    queues() {
        return AjaxHelper.get(`${baseUrl}/queues`, 'data');
    },
    createQueue(queue) {
        return AjaxHelper.post(`${baseUrl}/queues`, { data: queue }, 'data');
    },
    updateQueue(id, queue) {
        return AjaxHelper.patch(`${baseUrl}/queues/${id}`, { data: queue }, 'data');
    },
    deleteQueue(id) {
        return AjaxHelper.delete(`${baseUrl}/queues/${id}`);
    },
    selections() {
        return AjaxHelper.get(`${baseUrl}/selections`, 'data');
    },
    selectionStructure(type = 'application') {
        return AjaxHelper.get(`${baseUrl}/selections/structure?type=${type}`, 'data');
    },
    createSelection(selection) {
        return AjaxHelper.post(`${baseUrl}/selections`, { data: selection });
    },
    deleteSelection(id) {
        return AjaxHelper.delete(`${baseUrl}/selections/${id}`);
    },
    updateSelection(id, selection) {
        return AjaxHelper.patch(`${baseUrl}/selections/${id}`, { data: selection });
    },
    patch(user_id, user) {
        return AjaxHelper.patch(`${baseUrl}/users/${user_id}`, { data: user });
    },
    count(queueID) {
        return AjaxHelper.get(`${baseUrl}/queues/${queueID}/count`, 'data');
    },
    samples(queueID) {
        return AjaxHelper.get(`${baseUrl}/queues/${queueID}/samples`, 'data');
    },
    users() {
        return AjaxHelper.get(`${baseUrl}/users?includeHidden=false`, 'data');
    },
    get(id) {
        return AjaxHelper.get(`${baseUrl}queues/${id}`, 'data');
    },
    next() {
        const state = store.getState();
        const userId = state.auth.authData.user.id;
        return AjaxHelper.post(`${baseUrl}/queues/next`, { data: { user_id: userId } }, 'data');
    },
    callbacks() {
        return AjaxHelper.get(`${baseUrl}/callbacks`, 'data');
    },
    getUserCallbacks(userId) {
        return AjaxHelper.get(`${baseUrl}/users/${userId}/callbacks`, 'data');
    },
    createCallback(callback) {
        return AjaxHelper.post(`${baseUrl}/callbacks`, { data: callback }, 'data');
    },
    updateCallback(callbackId, callback) {
        return AjaxHelper.patch(`${baseUrl}/callbacks/${callbackId}`, { data: callback }, 'data');
    },
    deleteCallback(callbackId) {
        return AjaxHelper.delete(`${baseUrl}/callbacks/${callbackId}`);
    },
};
