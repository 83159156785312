import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';

import Form from './Form';
import Timer from './Timer';

class RenewLoginModal extends Component {
    render() {
        return (
            <Modal open closeOnDimmerClick={false} closeOnDocumentClick={false} closeOnEscape={false} size="mini">
                <Modal.Header>Stay logged in?</Modal.Header>
                <Modal.Content>
                    <Timer logout={this.props.renewToken} />
                </Modal.Content>
                <Modal.Content>
                    <Form {...this.props} />
                </Modal.Content>
            </Modal>
        );
    }
}

export default RenewLoginModal;
