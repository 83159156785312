import roles from 'APP/enums/User/Roles';
import { includes } from 'lodash';

import UserRoleEnums from '../enums/User/Roles';
import { UserTypes as UserTypeEnums } from '../enums/User/Type';
import Store from '../stores/ReduxStore';

export { UserRoleEnums, UserTypeEnums };

export default {
    // privs (array||string). Array or string of privilege(s) to check if the user has or not
    // strict means that none none of the privileges sent in can be false.
    // If false it will return true if one of the privileges is true
    hasAccess(privs, strict = false) {
        const state = Store.getState();
        const { privileges } = state.auth.authData;

        let lookup_privs = privs;
        // If the sent privs is not an array, but a string, convert to array for futher operations
        if (!Array.isArray(privs)) {
            lookup_privs = [privs];
        }
        // Get the list of privileges from authstore
        const user_privileges = privileges; // AuthStore.getAuthData().privileges;

        // if user privileges is empty activate this guard
        if (!user_privileges) {
            return false;
        }
        // Lodash includes returns true if the array is empty sometimes, therefor this guard
        if (user_privileges.length < 1) {
            return false;
        }
        const result = [];
        // Loop over and check if the privileges for lookup is in the array of privileges for the user
        lookup_privs.forEach((priv) => {
            result.push(includes(user_privileges, priv));
        }, this);

        let access = false;
        // If strict, all the sent privs have to be in the auth list, otherwise only one of them has to be
        if (strict) {
            access = !includes(result, false);
        } else {
            access = includes(result, true);
        }
        return access;
    },
    isUserType(userType) {
        const state = Store.getState();
        const userData = state.auth.authData;
        if (!userData) {
            return false;
        }
        return userData.user.user_type === userType;
    },
    // Checks both the privileges and user_type
    fullAccess(privs, userType, strict) {
        const hasPrivs = this.hasAccess(privs, strict);
        const hasUserType = this.isUserType(userType);
        return hasPrivs && hasUserType;
    },
    hasRole(checkRole) {
        const state = Store.getState();
        if (!state) {
            return false;
        }
        const { user } = state.auth.authData;
        return user.roles.includes(checkRole);
    },
    isPartner() {
        return (
            this.hasRole(roles.PARTNER_USER) || this.hasRole(roles.PARTNER_ADMIN) || this.hasRole(roles.PARTNERAPI_USER)
        );
    },
};
