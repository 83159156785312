import React from 'react';
import { Button } from 'semantic-ui-react';

function WithdrawnOfferButton() {
    return <Button disabled primary basic size="small" content={`Withdrawn`} floated="right" />;
}

WithdrawnOfferButton.propTypes = {};
WithdrawnOfferButton.defaultProps = {};

export default WithdrawnOfferButton;
