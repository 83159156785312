export const OPEN_INTEGRATION_MODAL = 'OPEN_INTEGRATION_MODAL';
export const CLOSE_INTEGRATION_MODAL = 'CLOSE_INTEGRATION_MODAL';

/**
 * @param {string} taskId
 * @param {string} applicationId
 * @param {string[]} productIds
 */
export function openIntegrationModal(taskId, applicationId, productIds) {
    return {
        type: OPEN_INTEGRATION_MODAL,
        payload: {
            taskId,
            applicationId,
            productIds,
        },
    };
}

export function closeIntegrationModal() {
    return {
        type: CLOSE_INTEGRATION_MODAL,
    };
}
