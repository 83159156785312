class Scroller {
    scrollContainer;

    scrollToBottom() {
        if (!this.scrollContainer) {
            this.scrollContainer = document.getElementById('scroll-container');
        }

        this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;
    }
}

export default new Scroller();
