import { getDropdownOptions } from './CommonHelper';

const complements = {
    business: {
        one_guarantor: 'One guarantor',
        two_guarantors: 'Two guarantors',
        limited_guarantor: 'Limited guarantor',
        pl_statement: 'Profit and loss statement',
        balance_sheet: 'Balance sheet',
        bank_statements: 'Bank statements',
        instantor_scraping: 'Instantor scraping',
        billing_and_accounting_data: 'Billing and accounting data',
        beneficial_owner_as_guarantor: 'Beneficial owner as guarantor',
        autogiro: 'Direct debit as only option',
        id_copy: 'Copy of id',
        company_mortgage: 'Company mortgage',
        liquidity_budget: 'Simple budget',
        property_collateral: 'Property collateral',
        invoice_collateral: 'Invoice collateral',
        eu_loan_guarantor: '20% guarantor',
        external_property_valuation: 'External property valuation',
        property_collateral_separate_company: 'Property collateral via separate company',
        eu_loan_100_guarantor: '100% guarantor EU-loan',
    },
    private: {
        id_copy: 'Copy of id',
        pay_slips: 'Pay slips',
        income_proof: 'Income proof',
        company_balance: 'Company balance',
        bank_statement: 'Bank statement',
        open_banking_account_information: 'Transaction History',
        income_verification: 'Income verification',
    },
};

const allComplements = { ...complements.business, ...complements.private };

const types = {
    PRIVATE: 'private',
    BUSINESS: 'business',
};

export default {
    getComplements(type = types.PRIVATE) {
        return complements[type];
    },

    allComplements,

    options: getDropdownOptions(allComplements),

    getComplementString: (key) => ({ ...complements.business, ...complements.private })[key] || key,

    renderComplement(complement) {
        let returnString;
        if (complement.type === 'pay_slips') {
            returnString = `${this.getComplementString(complement.type)}: ${complement.value}`;
        } else {
            returnString = this.getComplementString(complement.type);
        }

        return `${returnString}${complement.applicant_type === 'co' ? ' (co-applicant)' : ''}`;
    },

    types,
};
