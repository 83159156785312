export default {
    early_pension: 'Early pension',
    finite_time: 'Finite time',
    full_time: 'Full time',
    hourly_employment: 'Hourly employment',
    own_company: 'Own company',
    part_time: 'Part time',
    student: 'Student',
    pension: 'Pension',
    unemployed: 'Unemployed',
    condominium: 'Condominium',
    house: 'House',
    rental: 'Rental',
    tenant: 'Tentant',
    single: 'Single',
    live_together: 'Live together',
    married: 'Married',
    divorced: 'Divorced',
};
