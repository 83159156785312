import BusinessWithdrawalReasonsPopup from 'APP/components/BusinessWithdrawalReasonsPopup';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Popup } from 'semantic-ui-react';

const AcceptanceText = (
    <p>By withdrawing the offer, the customer will no longer be able to proceed with your loan offer.</p>
);

const NoAcceptanceText = (
    <p>
        By withdrawing an offer the offer will not be available for the applicant to choose from. You will still be able
        to send a new offer to the new revision.
    </p>
);

function WithdrawOfferButton(props) {
    const [showModal, setShowModal] = React.useState(false);

    if (props.isBusiness) {
        return (
            <React.Fragment>
                <BusinessWithdrawalReasonsPopup
                    showModal={showModal}
                    onClose={() => setShowModal(false)}
                    onClick={(args) => {
                        setShowModal(false);
                        props.onClick(args);
                    }}
                />
                <Button
                    floated="right"
                    size={props.small ? 'small' : undefined}
                    content="Withdraw"
                    basic
                    primary
                    onClick={() => setShowModal(true)}
                />
            </React.Fragment>
        );
    }

    return (
        <Popup
            on="click"
            trigger={
                <Button
                    floated="right"
                    primary
                    disabled={props.disabled}
                    basic
                    size={props.small ? 'small' : undefined}
                    content="Withdraw"
                />
            }
            header="Withdraw offer?"
            content={
                <div>
                    {props.withAcceptance ? AcceptanceText : NoAcceptanceText}
                    <Button fluid content="Yes, withdraw offer" onClick={props.onClick} />
                </div>
            }
        />
    );
}

WithdrawOfferButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    small: PropTypes.bool,
    withAcceptance: PropTypes.bool,
};
WithdrawOfferButton.defaultProps = {
    disabled: false,
    small: false,
    withAcceptance: false,
};

export default WithdrawOfferButton;
