import config from '../../config';
import AjaxHelper from '../../helpers/AjaxHelper';

const transport = AjaxHelper;
const baseUrl = config.partnerApi.prefix;
const usersUrl = `${baseUrl}/users`;

export default {
    list() {
        return transport.get(`${usersUrl}`, 'data');
    },

    delete(user) {
        return transport.delete(`${usersUrl}/${user.id}`);
    },

    create(user) {
        return transport.post(usersUrl, { data: user });
    },

    update(user) {
        return transport.patch(`${usersUrl}/${user.id}`, { data: user }, 'data');
    },

    generatePassword(user) {
        return transport.post(`${usersUrl}/${user.id}/password`, { data: {} }, 'data');
    },
};
