import CsrfStore from '../stores/CsrfStore';

/* eslint-disable import/prefer-default-export */

export function getCSRFTokenHeaders() {
    const csrf = CsrfStore.getCsrf();
    if (!csrf) {
        return {};
    }
    return { 'x-csrf-token': csrf };
}
