export function createEffect(type, args = {}) {
    return {
        type,
        status: 'idle',
        markAsStarted() {
            this.status = 'started';
        },
        ...args,
    };
}
