// This file should contain helpers for numbers such as formatting currency and
// such

export default {
    // Formats a number, ex. 232500 to 232 500 Accepts strings
    currency(string, currency = undefined) {
        if (string === null) {
            return null;
        }

        const str = this.thousandSep(string, ' ');
        if (currency) {
            return `${str} ${currency}`;
        }

        return str;
    },
    thousandSep(string = '', separator = ' ') {
        const x = `${string}`.split('.');
        let x1 = x[0];
        const x2 = x.length > 1 ? `.${x[1]}` : '';
        const rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, `$1${separator}$2`);
        }

        return x1 + x2;
    },
    ssn(ssn) {
        return `${ssn.substr(0, ssn.length - 4)}-${ssn.substring(ssn.length - 4)}`;
    },

    decimals(value) {
        return value.toFixed(2);
    },
};
