export default {
    ADMIN_ADMIN: 'ROLE_ADMIN_ADMIN',
    ADMIN_CUSTOMER_SALES: 'ROLE_ADMIN_CUSTOMER_SALES',
    ADMIN_CUSTOMER_SALES_INSURANCE: 'ROLE_ADMIN_CUSTOMER_SALES_INSURANCE',
    ADMIN_CUSTOMER_SALES_PLUS: 'ROLE_ADMIN_CUSTOMER_SALES_PLUS',
    ADMIN_CUSTOMER_SALES_RETAIN: 'ROLE_ADMIN_CUSTOMER_SALES_RETAIN',
    ROLE_ADMIN_CUSTOMER_SALES_BUSINESS: 'ROLE_ADMIN_CUSTOMER_SALES_BUSINESS',
    ADMIN_CUSTOMER_SALES_MORTGAGES: 'ROLE_ADMIN_CUSTOMER_SALES_MORTGAGES',
    ADMIN_CUSTOMER_SERVICE: 'ROLE_ADMIN_CUSTOMER_SERVICE',
    ADMIN_CUSTOMER_SERVICE_PLUS: 'ROLE_ADMIN_CUSTOMER_SERVICE_PLUS',
    ROLE_ADMIN_COMPRICER_SERVICE: 'ROLE_ADMIN_COMPRICER_SERVICE',
    ADMIN_SUPERUSER: 'ROLE_ADMIN_SUPERUSER',
    ADMIN_BETA_TESTER: 'ROLE_ADMIN_BETA_TESTER',
    PARTNER_ADMIN: 'ROLE_PARTNER_ADMIN',
    PARTNERAPI_USER: 'ROLE_PARTNERAPI_USER',
    PARTNER_USER: 'ROLE_PARTNER_USER',
};
