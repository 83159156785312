import config from '../../config';
import AjaxHelper from '../../helpers/AjaxHelper';

const baseUrl = `${config.partnerApi.prefix}`;

const transport = AjaxHelper;

export default {
    getBusinessPropertyQuestions: (applicationId) =>
        transport.get(`${baseUrl}/business/property-questions/${applicationId}`, 'data'),

    // ----------------------------------------------
};
