import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Statistic } from 'semantic-ui-react';

import { aboutToExpireTime } from '../../../../config/auth';

class Timer extends Component {
    state = {
        time: null,
    };

    tick = () => {
        this.setState(() => ({
            time: this.state.time - 1,
            displayTime: this.secondsToTime(this.state.time - 1),
        }));
        if (this.state.time === 0) {
            clearInterval(this.interval);
            this.props.logout();
        }
    };

    componentDidMount() {
        this.setState(() => ({
            time: aboutToExpireTime + 1,
            displayTime: this.secondsToTime(aboutToExpireTime + 1),
        }));
        this.interval = setInterval(this.tick, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    secondsToTime = (s) => {
        const divisorMinutes = s % (60 * 60);
        const minutes = Math.floor(divisorMinutes / 60);
        const divisorSeconds = divisorMinutes % 60;
        const seconds = Math.ceil(divisorSeconds);

        const timeObj = {
            m: minutes,
            s: seconds < 10 ? `0${seconds}` : seconds,
        };
        return timeObj;
    };

    render() {
        if (!this.state.time) {
            return null;
        }
        return (
            <Statistic
                size="small"
                label="Time left"
                value={
                    this.state.displayTime.m > 0
                        ? `${this.state.displayTime.m}:${this.state.displayTime.s}`
                        : this.state.displayTime.s
                }
                style={{ margin: '0 auto' }}
            />
        );
    }
}

Timer.propTypes = {
    logout: PropTypes.func.isRequired,
};

export default Timer;
