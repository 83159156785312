// Expose functionality related to queue core.
export const QUEUE_CORE = false;

// Expose the new private application view
export const NEW_APPLICATION_VIEW_PRIVATE = false;

// Expose the new business application view
export const NEW_APPLICATION_VIEW_BUSINESS = false;

// Enables Notifications
export const ENABLE_NOTIFICATIONS_NAVBAR_ITEM = true;
