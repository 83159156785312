import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Segment, Table } from 'semantic-ui-react';

import ResponseHelper from '../../../../../../helpers/ResponseHelper';
import ResponseRow from './ResponseRow';
import RevisionRow from './RevisionRow';

class RevisionsTable extends React.Component {
    state = { expandedRevisions: [] };

    UNSAFE_componentWillMount() {
        this.showHighestRevisionWithResponses(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.showHighestRevisionWithResponses(nextProps);
    }

    showHighestRevisionWithResponses(props) {
        const { revision: highestRevisionWithResponses } = props.responses.reduce(
            (a, b) => (a.revision > b.revision ? a : b),
            {}
        );

        if (this.state.expandedRevisions.includes(highestRevisionWithResponses)) {
            return;
        }

        this.setState({ expandedRevisions: [...this.state.expandedRevisions, highestRevisionWithResponses] });
    }

    toggleExpandedRevision = (revision) => {
        if (this.state.expandedRevisions.includes(revision)) {
            this.setState({ expandedRevisions: this.state.expandedRevisions.filter((r) => r !== revision) });
        } else {
            this.setState({ expandedRevisions: [...this.state.expandedRevisions, revision] });
        }
    };

    render() {
        const { revisions, responses } = this.props;

        if (!revisions.length) {
            return null;
        }

        const sortedRevisions = revisions.sort((a, b) => b.revision - a.revision);

        const latestActiveLoanOffer = responses
            .filter(ResponseHelper.isActive)
            .reduce((a, b) => (a.created_at > b.created_at ? a : b), {});

        return (
            <Segment>
                <Table basic="very" selectable singleLine>
                    <Table.Body>
                        {sortedRevisions.map((applicationRevision) => {
                            const expanded = this.state.expandedRevisions.includes(applicationRevision.revision);
                            const currentResponses = responses.filter(
                                (r) => r.revision === applicationRevision.revision
                            );
                            const hasResponses = !!currentResponses.length;

                            const isCurrentRevision = applicationRevision.revision === this.props.currentRevision;

                            return [
                                <RevisionRow
                                    key={applicationRevision.revision}
                                    handleSubmit={this.toggleExpandedRevision}
                                    isCurrentRevision={isCurrentRevision}
                                    applicationRevision={applicationRevision}
                                    hasResponses={hasResponses}
                                    expanded={expanded}
                                />,
                                expanded &&
                                    hasResponses &&
                                    responses
                                        .filter((r) => r.revision === applicationRevision.revision) // Only show responses connected to this applicationRevision
                                        .sort((r1, r2) => moment(r2.created_at).diff(moment(r1.created_at)))
                                        .map((r) => (
                                            <ResponseRow
                                                key={r.id}
                                                response={r}
                                                disableButtons={this.props.disableButtons}
                                                latestActiveLoanOffer={r.id === latestActiveLoanOffer.id}
                                                resendOfferCallback={this.props.resendOfferCallback}
                                                withdrawOfferCallback={this.props.withdrawOfferCallback}
                                                currentRevision={this.props.currentRevision}
                                            />
                                        )),
                            ];
                        })}
                    </Table.Body>
                </Table>
            </Segment>
        );
    }
}

RevisionsTable.propTypes = {
    currentRevision: PropTypes.number.isRequired,
    revisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    responses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    disableButtons: PropTypes.bool.isRequired,
    resendOfferCallback: PropTypes.func.isRequired,
    withdrawOfferCallback: PropTypes.func.isRequired,
};
RevisionsTable.defaultProps = {};

export default RevisionsTable;
