import React, { useState } from 'react';
import { Button, Dropdown, Label, Modal } from 'semantic-ui-react';

const withdrawOptions = [
    {
        text: 'Not interested (active decline)',
        value: 'not_interested',
        key: 'not_interested',
    },
    {
        text: 'No response (passive decline)',
        value: 'no_response',
        key: 'no_response',
    },
    {
        text: 'Did not send back credit agreement',
        value: 'did_not_send_back_credit_agreement',
        key: 'did_not_send_back_credit_agreement',
    },
    {
        text: 'Debt balance (kronofogden)',
        value: 'debt_balance',
        key: 'debt_balance',
    },
    {
        text: 'Guarantor not OK / does not exist',
        value: 'invalid_guarantor',
        key: 'invalid_guarantor',
    },
    {
        text: 'Revenue is not enough',
        value: 'not_enough_revenue',
        key: 'not_enough_revenue',
    },
    {
        text: 'Too much existing loans',
        value: 'too_much_existing_loans',
        key: 'too_much_existing_loans',
    },
    {
        text: 'Profitability not OK',
        value: 'profitability_not_ok',
        key: 'profitability_not_ok',
    },
    {
        text: 'Background check of individual not OK',
        value: 'background_check_not_ok',
        key: 'background_check_not_ok',
    },
    {
        text: 'Fraud',
        value: 'fraud',
        key: 'fraud',
    },
    {
        text: 'Other',
        value: 'other',
        key: 'other',
    },
];

export default function BusinessWithdrawalReasonsPopup(props) {
    const [reason, setReason] = useState('');
    const [error, setError] = useState('');

    function handleClick() {
        if (!reason) {
            setError('Reason is required before withdrawing an offer.');
        } else {
            props.onClick({ reason });
        }
    }

    function handleChange(event, data) {
        if (data.value) {
            setError('');
            setReason(data.value);
        }
    }

    return (
        <Modal size="small" open={props.showModal}>
            <Modal.Header>Select reason for withdraw</Modal.Header>
            <Modal.Content>
                {props.text}
                <Dropdown
                    error={!!error}
                    selection
                    fluid
                    value={reason}
                    onChange={handleChange}
                    options={withdrawOptions}
                    placeholder="Select a reason"
                />
                {error && (
                    <Label pointing color="red">
                        {error}
                    </Label>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button content="Cancel" basic onClick={props.onClose} />
                <Button content="Confirm" primary onClick={handleClick} />
            </Modal.Actions>
        </Modal>
    );
}
