const TYPES = {
    PRIVATE: 'private',
    BUSINESS: 'business',
    MORTGAGE: 'mortgage',

    MORTGAGE_NEW_LOAN: 'new',
    MORTGAGE_REFINANCE: 'refinance',
    MORTGAGE_EXTEND_LOAN: 'expand',
    MORTGAGE_LOAN_PROMISE: 'loan_promise',
};

export default TYPES;
