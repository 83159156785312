import PropTypes from 'prop-types';
import React from 'react';
import { Label } from 'semantic-ui-react';

const riskClassToColorMapping = {
    1: 'red',
    2: 'yellow',
    3: 'green',
    4: 'green',
    5: 'green',
    green: 'green',
    yellow: 'yellow',
    red: 'red',
};

function RiskIndicatorLabel(props) {
    if (!props.company.credit_data || !props.company.credit_data.risk_class) {
        return null;
    }

    const riskLevel = props.company.credit_data.risk_class;

    let InnerLabel;

    // If we get 'unknown'
    if (!Object.keys(riskClassToColorMapping).includes(riskLevel)) {
        InnerLabel = <span>N/A</span>;
    } else {
        InnerLabel = (
            <Label
                style={{ textTransform: 'capitalize' }}
                circular
                color={riskClassToColorMapping[riskLevel]}
                content={riskLevel}
            />
        );
    }

    if (props.labelled) {
        return <div>Risk class: {InnerLabel}</div>;
    }

    return InnerLabel;
}

RiskIndicatorLabel.propTypes = {
    company: PropTypes.shape({
        credit_data: PropTypes.shape({
            risk_class: PropTypes.string,
        }),
    }).isRequired,
    labelled: PropTypes.bool,
};

RiskIndicatorLabel.defaultProps = {
    labelled: false,
};

export default RiskIndicatorLabel;
