import ReponseHelper from 'APP/helpers/ResponseHelper';
import PropTypes from 'prop-types';
import React from 'react';

import DenialRow from './DenialRow';
import LoanOfferRow from './LoanOfferRow';

function ResponseRow(props) {
    return ReponseHelper.isLoanOffer(props.response) ? <LoanOfferRow {...props} /> : <DenialRow {...props} />;
}

ResponseRow.propTypes = {
    currentRevision: PropTypes.number,
    response: PropTypes.object.isRequired,
    resendOfferCallback: PropTypes.func,
    disableButtons: PropTypes.bool.isRequired,
    withdrawOfferCallback: PropTypes.func.isRequired,
};
ResponseRow.defaultProps = {
    currentRevision: null,
    resendOfferCallback: null,
};

export default ResponseRow;
