import ApplicationTypes from 'APP/enums/Application/Types';

import config from '../config';
import AjaxHelper from '../helpers/AjaxHelper';

const baseUrl = `${config.api.prefix}/applicants`;
const zissonUrl = `${config.api.prefix}/zisson`;
const transport = AjaxHelper;

export default {
    update: (id, applicationType = ApplicationTypes.PRIVATE, data) =>
        transport.patch(`${baseUrl}/${applicationType}/${id}`, { data }),

    prefill: (ssn) => {
        return transport.post(`${baseUrl}/prefill`, { data: { ssn } }, 'data');
    },

    clickToCall: (phoneNumber, autoAnswer) => {
        return transport.post(`${zissonUrl}/click-to-call`, {
            to_number: phoneNumber,
            auto_answer: autoAnswer,
        });
    },
};
