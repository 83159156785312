import { setAuthState } from 'APP/actions/AuthAction';
import Axios from 'axios';

import CsrfStore from '../stores/CsrfStore';
import store from '../stores/ReduxStore';
import { getCSRFTokenHeaders } from './CsrfHelper';

const axios = Axios.create({
    headers: { Pragma: 'no-cache' },
});

class StatusCodeError extends Error {
    constructor(message, statusCode, correlationId, responseUrl) {
        super(message);
        this.statusCode = statusCode;
        this.correlationId = correlationId;
        this.responseUrl = responseUrl;
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error(message).stack;
        }
    }
}

export default {
    /**
     * @param {string} url
     * @param {{}} data
     * @returns {Promise}
     */
    get(url, fields) {
        return axios
            .get(this.simplifiedQueryStringAppend(url), { headers: getCSRFTokenHeaders() })
            .then((resp) => this.parseResponseData(resp, fields), this.parseError);
    },

    /**
     * @param {string} url
     * @param {{}} data
     * @returns {Promise}
     */
    post(url, data, fields) {
        return axios
            .post(this.simplifiedQueryStringAppend(url), data, { headers: getCSRFTokenHeaders() })
            .then((resp) => this.parseResponseData(resp, fields), this.parseError);
    },

    /**
     * @param {string} url
     * @param {{}} data
     * @returns {Promise}
     */
    put(url, data, fields) {
        return axios
            .put(this.simplifiedQueryStringAppend(url), data, { headers: getCSRFTokenHeaders() })
            .then((resp) => this.parseResponseData(resp, fields), this.parseError);
    },

    /**
     * @param {string} url
     * @param {{}} data
     * @returns {Promise}
     */
    patch(url, data, fields) {
        return axios
            .patch(this.simplifiedQueryStringAppend(url), data, { headers: getCSRFTokenHeaders() })
            .then((resp) => this.parseResponseData(resp, fields), this.parseError);
    },

    /**
     * @param {string} url
     * @returns {Promise}
     */
    delete(url, fields) {
        return axios
            .delete(this.simplifiedQueryStringAppend(url), { headers: getCSRFTokenHeaders() })
            .then((resp) => this.parseResponseData(resp, fields), this.parseError);
    },

    /**
     * @param {{}} response
     */
    async parseResponseData(response, fields) {
        if (typeof response === 'undefined' || response.data === 'undefined') {
            throw new Error('Could not parse the response data.');
        }
        if (typeof response.data.auth === 'object' && Object.keys(response.data.auth).length > 0) {
            // AuthStore.setAuthData(response.data.auth);
            // store.dispatch(setAuthData(response.data.auth));
        }
        if (response.headers['x-csrf-token']) {
            CsrfStore.setCsrf(response.headers['x-csrf-token']);
        }

        if (typeof fields !== 'undefined') {
            return this.getOrReject(response, fields);
        }

        return response;
    },

    async parseError(error) {
        if (error.response?.headers['x-csrf-token']) {
            CsrfStore.setCsrf(error.response.headers['x-csrf-token']);
        }

        if (!error?.response?.status) {
            throw new Error('Could not contact the server.');
        }

        if (typeof error.response.data === 'undefined') {
            throw new Error('Got an error but could not decode the body');
        }
        const data = error.response.data;
        const correlationId = error.response.headers['x-correlation-id'];

        // User is logged out
        if (data.status === 'token_error') {
            store.dispatch(setAuthState('expired'));
        }

        if (typeof data.message !== 'undefined') {
            throw new StatusCodeError(data.message, error.response.status, correlationId, error.request.responseURL);
        } else {
            throw new StatusCodeError(
                'Got an error but could not decode the body',
                error.response.status,
                correlationId,
                error.request.responseURL
            );
        }
    },

    /**
     * @param {string} url
     * @param {{}} data
     * @returns {string}
     */
    simplifiedQueryStringAppend(url, data) {
        if (!data) {
            return url;
        }

        const queryMap = {};
        const urlParts = url.split('?', 2);
        if (urlParts.length === 2) {
            const queryParts = urlParts[1].split('?');
            for (const queryPart of queryParts) {
                const valueParts = queryPart.split('=', 2);
                queryMap[valueParts[0]] = valueParts[1];
            }
        }
        for (const k of Object.keys(data)) {
            queryMap[k] = data[k];
        }

        url = urlParts[0];
        let i = 0;
        for (const k of Object.keys(queryMap)) {
            url += i++ ? '&' : '?';
            url += `${k}=${queryMap[k]}`;
        }

        return url;
    },

    /**
     * @param {{}} data
     * @param {string} fields
     * @returns {Promise}
     */
    getOrReject(data, fields) {
        let currentData = data.data;
        for (const field of fields.split('.')) {
            if (typeof currentData[field] === 'undefined') {
                return Promise.reject(new Error(`Can't find field "${fields}" from response`));
            }
            currentData = currentData[field];
        }

        return Promise.resolve(currentData);
    },
};
