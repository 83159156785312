import { getTasksDetailsForApplicationProducts } from 'APP/actions/IntegrationsActions';
import { HandleException } from 'APP/helpers/ErrorHelper';
import { closeIntegrationModal } from 'APP/ReduxActions/IntegrationModalActions';
import React, { Fragment, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Table } from 'semantic-ui-react';

import GeneralLoader from '../GeneralLoader';
import style from './IntegrationModal.css';
import TaskEntry from './IntegrationModal/TaskEntry';

/**
 * @param {Object[]} tasks
 */
function sortByDate(tasks) {
    return tasks.sort((a, b) => new Date(b.last_executed_at) - new Date(a.last_executed_at));
}

const initialState = {
    loading: false,
    error: null,
    integrationTasks: [],
};

const FETCH_DONE = 'FETCH_DONE';
const FETCH_STARTED = 'FETCH_STARTED';
const FETCH_ERROR = 'FETCH_ERROR';
const RESET = 'RESET';

function reducer(state, action) {
    switch (action.type) {
        case FETCH_STARTED:
            return {
                integrationTasks: [],
                loading: true,
                error: null,
            };
        case FETCH_DONE:
            return {
                integrationTasks: action.payload.integrationTasks,
                loading: false,
                error: null,
            };
        case FETCH_ERROR:
            return {
                integrationTasks: [],
                loading: false,
                error: action.payload.error,
            };
        case RESET:
            return {
                integrationTasks: [],
                loading: false,
                error: null,
            };
        default:
            return state;
    }
}

async function fetchIntegrations(applicationId, productIds, dispatch) {
    try {
        dispatch({ type: FETCH_STARTED });
        const tasks = await getTasksDetailsForApplicationProducts(applicationId, productIds);
        if (!tasks) {
            return;
        }

        const integrationTasks = sortByDate(tasks);

        dispatch({ type: FETCH_DONE, payload: { integrationTasks } });
    } catch (error) {
        HandleException(error, 'Failed to load integrations');
    }
}

/**
 * @param {Object} props
 * @param {string} props.taskId
 * @param {string} props.applicationId
 * @param {string[]} props.productIds
 * @param {(action: any) => void} props.dispatch
 */
function IntegrationModal({ applicationId, productIds, dispatch, open }) {
    const [integrationTasksState, integrationTasksDispatch] = useReducer(reducer, initialState);
    const { loading, integrationTasks } = integrationTasksState;

    useEffect(() => {
        if (open) {
            fetchIntegrations(applicationId, productIds, integrationTasksDispatch);
        }
    }, [applicationId, open, productIds]);

    function onClose() {
        integrationTasksDispatch({ type: RESET });
        dispatch(closeIntegrationModal());
    }

    function updateModal() {
        fetchIntegrations(applicationId, productIds, integrationTasksDispatch);
    }

    function createProductHeaders() {
        if (!integrationTasks || !integrationTasks.length) {
            return '';
        }
        // Create a set to prevent duplicates.
        const productNames = new Set(...integrationTasks.map((task) => Object.values(task.products)));

        return Array.from(productNames.values()).join(', ');
    }

    return (
        <Modal data-testid="integration-modal" open={open} onClose={onClose} size="large">
            <Fragment>
                <Modal.Header>
                    <div className={style.headerWrapper}>
                        <h1>Product(s): {createProductHeaders()}</h1>
                        <Button icon="refresh" basic onClick={updateModal} />
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <Table size="large" unstackable>
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell>Id</Table.HeaderCell>
                                <Table.HeaderCell>Last run</Table.HeaderCell>
                                <Table.HeaderCell>Product(s)</Table.HeaderCell>
                                <Table.HeaderCell>Action</Table.HeaderCell>
                                <Table.HeaderCell>Runs</Table.HeaderCell>
                                <Table.HeaderCell>Revision</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell colSpan="12" />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {loading && (
                                <Table.Row>
                                    <Table.Cell colSpan="12">
                                        <GeneralLoader />
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            {!loading &&
                                integrationTasks.map((task) => (
                                    <TaskEntry updateModal={updateModal} key={task.id} task={task} />
                                ))}
                        </Table.Body>
                    </Table>
                </Modal.Content>
            </Fragment>
        </Modal>
    );
}

function mapStateToProps(state) {
    return { ...state.integrationModal };
}

export default connect(mapStateToProps)(IntegrationModal);
