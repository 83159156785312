import ExplanationPopup from 'APP/common/ExplanationPopup';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Header, Segment } from 'semantic-ui-react';

import DenyButton from '../../../../../components/PartnerApplication/common/DenyButton';

function NewRevisionInformationBanner(props) {
    const { productId, application, denyCallback, sendNewOfferCallback } = props;
    return (
        <Segment clearing>
            <div style={{ float: 'right' }}>
                <Button color="green" icon="plus" content="Send new offer" onClick={sendNewOfferCallback} />
                <DenyButton
                    callback={denyCallback}
                    applicationType={application.type}
                    applicationId={application.id}
                    productId={productId}
                    revision={application.revision}
                />
            </div>
            <Header floated="left">
                <Header.Content>New revision received</Header.Content>
                <Header.Subheader>
                    Please choose action to the right
                    <ExplanationPopup>
                        You have received a new revision of the application. In order for the revision to be seen as
                        treated, you must reply by either sending a new offer or deny the application
                    </ExplanationPopup>
                </Header.Subheader>
            </Header>
        </Segment>
    );
}

NewRevisionInformationBanner.propTypes = {
    productId: PropTypes.string.isRequired,
    application: PropTypes.object,
    sendNewOfferCallback: PropTypes.func.isRequired,
    denyCallback: PropTypes.func.isRequired,
};
NewRevisionInformationBanner.defaultProps = {};

export default NewRevisionInformationBanner;
