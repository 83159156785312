export const Enum = {
    CONDOMINIUM: 'condominium',
    HOUSE: 'house',
    RENTAL: 'rental',
    TENANT: 'tenant',
};

export const Options = [
    { value: Enum.CONDOMINIUM, text: 'Condominium' },
    { value: Enum.HOUSE, text: 'House' },
    { value: Enum.RENTAL, text: 'Rental' },
    { value: Enum.TENANT, text: 'Tenant' },
];

export const AccomodationOptions = {
    condominium: 'Condominium',
    house: 'House',
    rental: 'Rental',
    tenant: 'Tenant',
};
