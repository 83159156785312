import GeneralLoader from 'APP/components/GeneralLoader';
import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';

import SidebarLayout from '../layouts/SidebarLayout';

function PrivateRoute(props) {
    const { component: Component, type, ...rest } = props;

    return (
        <Route
            {...rest}
            render={(props) => (
                <SidebarLayout>
                    <Suspense fallback={<GeneralLoader />}>
                        <Component type={type} {...props} />
                    </Suspense>
                </SidebarLayout>
            )}
        />
    );
}

export default PrivateRoute;
