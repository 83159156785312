const _IntegrationStatus = {
    ERROR: 'error',
    FINISHED: 'finished',
    PROCESSING: 'processing',
    TRANSIENT_ERROR: 'transient_error',
    PENDING: 'pending',
};

export const IntegrationStatus = {
    status: _IntegrationStatus,
    text: {
        [_IntegrationStatus.ERROR]: 'Error',
        [_IntegrationStatus.FINISHED]: 'Finished',
        [_IntegrationStatus.PROCESSING]: 'Processing',
        [_IntegrationStatus.TRANSIENT_ERROR]: 'Transient Error',
        [_IntegrationStatus.PENDING]: 'Pending',
    },
    colors: {
        [_IntegrationStatus.ERROR]: 'red',
        [_IntegrationStatus.FINISHED]: 'green',
        [_IntegrationStatus.PROCESSING]: 'grey',
        [_IntegrationStatus.TRANSIENT_ERROR]: 'yellow',
        [_IntegrationStatus.PENDING]: 'grey',
    },
};
