import PropTypes from 'prop-types';
import React from 'react';
import { Image } from 'semantic-ui-react';

import style from './style.css';

const logo = require('APP/assets/logo_white.svg');

const logo_style = {
    margin: '0 auto',
    paddingTop: '15px',
};

const UserProfileComponent = () => (
    <div className={style.profileWrapper}>
        <Image src={logo} size="tiny" style={logo_style} />
    </div>
);

UserProfileComponent.propTypes = {
    user: PropTypes.object.isRequired,
};

export default UserProfileComponent;
