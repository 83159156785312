import Field from '@lendoab/lendo-redux-form-helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'semantic-ui-react';

const ComplementsPrivate = (props) => {
    const hasCo = !!props.coApplicant;
    const mainPrefix = `complements.${props.mainApplicant.id}`;
    const coPrefix = `complements.${props.coApplicant ? props.coApplicant.id : ''}`;

    return (
        <Table basic="very" collapsing celled selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Complement</Table.HeaderCell>
                    <Table.HeaderCell>Main</Table.HeaderCell>
                    {hasCo && <Table.HeaderCell>Co</Table.HeaderCell>}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>Copy of id</Table.Cell>
                    <Table.Cell>
                        <Field component="checkbox" name={`${mainPrefix}.id_copy`} toggle />
                    </Table.Cell>
                    {hasCo && (
                        <Table.Cell>
                            <Field component="checkbox" name={`${coPrefix}.id_copy`} toggle />
                        </Table.Cell>
                    )}
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Pay slips</Table.Cell>
                    <Table.Cell>
                        <Field component="input" name={`${mainPrefix}.pay_slips`} type="number" parse="int" />
                    </Table.Cell>
                    {hasCo && (
                        <Table.Cell>
                            <Field component="input" name={`${coPrefix}.pay_slips`} type="number" parse="int" />
                        </Table.Cell>
                    )}
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Income proof</Table.Cell>
                    <Table.Cell>
                        <Field component="checkbox" name={`${mainPrefix}.income_proof`} toggle />
                    </Table.Cell>
                    {hasCo && (
                        <Table.Cell>
                            <Field component="checkbox" name={`${coPrefix}.income_proof`} toggle />
                        </Table.Cell>
                    )}
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Company balance</Table.Cell>
                    <Table.Cell>
                        <Field component="checkbox" name={`${mainPrefix}.company_balance`} toggle />
                    </Table.Cell>
                    {hasCo && (
                        <Table.Cell>
                            <Field component="checkbox" name={`${coPrefix}.company_balance`} toggle />
                        </Table.Cell>
                    )}
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Bank statement</Table.Cell>
                    <Table.Cell>
                        <Field component="checkbox" name={`${mainPrefix}.bank_statement`} toggle />
                    </Table.Cell>
                    {hasCo && (
                        <Table.Cell>
                            <Field component="checkbox" name={`${coPrefix}.bank_statement`} toggle />
                        </Table.Cell>
                    )}
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Income verification</Table.Cell>
                    <Table.Cell>
                        <Field component="checkbox" name={`${mainPrefix}.income_verification`} toggle />
                    </Table.Cell>
                    {hasCo && (
                        <Table.Cell>
                            <Field component="checkbox" name={`${coPrefix}.income_verification`} toggle />
                        </Table.Cell>
                    )}
                </Table.Row>
            </Table.Body>
        </Table>
    );
};

ComplementsPrivate.propTypes = {
    mainApplicant: PropTypes.object.isRequired,
    coApplicant: PropTypes.object,
};

ComplementsPrivate.defaultProps = {};

export default ComplementsPrivate;
