import PropTypes from 'prop-types';
import React from 'react';
import { Header } from 'semantic-ui-react';

import ComplementsBusiness from './ComplementsBusiness';
import ComplementsPrivate from './ComplementsPrivate';

function Complements(props) {
    return (
        <div>
            <Header>Complements</Header>
            {props.isBusiness ? (
                <ComplementsBusiness
                    mainApplicant={props.mainApplicant}
                    toggleGuarantorComplement={props.toggleGuarantorComplement}
                />
            ) : (
                <ComplementsPrivate mainApplicant={props.mainApplicant} coApplicant={props.coApplicant} />
            )}
        </div>
    );
}

Complements.propTypes = {
    mainApplicant: PropTypes.object.isRequired,
    coApplicant: PropTypes.object,
    isBusiness: PropTypes.bool,
    toggleGuarantorComplement: PropTypes.func,
};
Complements.defaultProps = {
    isBusiness: false,
    toggleGuarantorComplement: () => () => {},
};

export default Complements;
