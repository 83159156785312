import './style.css';

import {
    Box,
    Button,
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationDefaultIcon,
    InfoIcon,
    Text,
} from '@lendoab/aphrodite';
import ClipboardHelper from 'APP/helpers/ClipboardHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';

// Error Message for correlation ID copy/paste button
function CustomErrorMessage({ correlationId, description, message, url, statusCode, responseUrl }) {
    const copyParts = [
        message ? 'message: ' + message : null,
        description ? 'description: ' + description : null,
        url ? 'url: ' + url : null,
        correlationId ? 'correlationId: ' + correlationId : null,
        statusCode ? 'statusCode: ' + statusCode : null,
        responseUrl ? 'responseUrl: ' + responseUrl : null,
    ].filter((n) => n);

    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <Box className="icon-error">
                <ExclamationCircleIcon color="red" style={{ marginRight: '10px' }} />
            </Box>
            <Box className="message-body-error">
                <Text size="xs">{description}</Text>
                <Text size="xs">{message}</Text>
                <Box display="flex" flexDirection="row" alignItems="center">
                    {correlationId ? (
                        <Box>
                            <Text size="xs" marginRight="xs">
                                Correlation-ID: {correlationId}
                            </Text>
                        </Box>
                    ) : (
                        <Box>
                            {statusCode ? (
                                <Text size="xs" marginRight="xs">
                                    Response status code: {statusCode}
                                </Text>
                            ) : null}
                            {responseUrl ? (
                                <Text size="xs" marginRight="xs">
                                    Endpoint: {responseUrl}
                                </Text>
                            ) : null}
                        </Box>
                    )}
                    {copyParts.length ? (
                        <Button
                            style={{ maxHeight: '30px', padding: '6px' }}
                            onClick={() => ClipboardHelper.copy(copyParts.join('\n'))}
                        >
                            Copy
                        </Button>
                    ) : null}
                </Box>
            </Box>
        </Box>
    );
}

// Custom Toast allows us to style the toasts however we would like (i.e. add icons)
function CustomToast(props) {
    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <Box display="flex" alignItems="center">
                {props.icon}
            </Box>
            <Box className="message-body-error">{props.content}</Box>
        </Box>
    );
}

// Custom Toast allows us to style the toasts however we would like (i.e. add icons)
function CustomToastLink(props) {
    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <Box display="flex" alignItems="center">
                {props.icon}
            </Box>
            <Box className="message-body-error">
                {props.content}{' '}
                <Text size="xxs" color="blue" component="a" href="/change-password">
                    Change password
                </Text>
            </Box>
        </Box>
    );
}

export default function Toast(type, content, autoCloseDuration = 3000) {
    const location = window.location;

    // Switch case to see what type of toast we should use
    switch (type) {
        case 'error':
            // If content is an object, which it is only in HandleException, then we will use CustomErrorMessage.
            if (typeof content === 'object') {
                toast.error(
                    <CustomErrorMessage
                        correlationId={content.correlationId}
                        statusCode={content.statusCode}
                        description={content.description}
                        message={content.message}
                        url={location.href}
                        responseUrl={content.responseUrl}
                    />,
                    {
                        autoClose: false,
                        className: 'error-toast',
                        closeOnClick: false,
                        position: toast.POSITION.BOTTOM_CENTER,
                    }
                );
                // Else the error message's content is just a string
            } else {
                toast.error(
                    <CustomToast
                        content={content}
                        icon={<ExclamationCircleIcon color="red" style={{ marginRight: '10px' }} />}
                    />,
                    { className: 'error-toast' }
                );
            }
            break;
        case 'success':
            toast.success(
                <CustomToast
                    content={content}
                    icon={<CheckCircleIcon color="green-middle" style={{ marginRight: '10px' }} />}
                />,
                {
                    className: 'success-toast',
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: autoCloseDuration,
                }
            );
            break;
        case 'info':
            toast.info(
                <CustomToast
                    content={content}
                    icon={<InfoIcon color="blue-primary" style={{ marginRight: '10px' }} />}
                />,
                {
                    className: 'info-toast',
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: autoCloseDuration,
                }
            );
            break;
        case 'warning':
            toast.warning(
                <CustomToast
                    content={content}
                    icon={<ExclamationDefaultIcon color="orange" style={{ marginRight: '10px' }} />}
                />,
                {
                    className: 'warning-toast',
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: autoCloseDuration,
                }
            );
            break;
        case 'warning-password':
            toast.warning(
                <CustomToastLink
                    content={content}
                    icon={<ExclamationDefaultIcon color="orange" style={{ marginRight: '10px' }} />}
                />,
                {
                    className: 'warning-toast',
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: false,
                    closeOnClick: false,
                }
            );
    }
}

Toast.propTypes = {
    type: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            correlationId: PropTypes.number,
            description: PropTypes.string,
            message: PropTypes.string,
        }),
    ]),
};

Toast.defaultProps = {
    type: 'info',
    content: '',
};
