import ResponseHelper from 'APP/helpers/ResponseHelper';
import PropTypes from 'prop-types';
import React from 'react';
import { Step } from 'semantic-ui-react';

import styles from './index.css';
import ResponseStep from './ResponseStep';
import STEPS from './Steps';

const getActiveStep = (props) => {
    if (props.loanPaidOut) {
        return STEPS.LOAN_PAID;
    }

    if (props.billOfDebtSent) {
        return STEPS.BOD_SENT;
    }

    if (props.hasAcceptance && !props.withDrawnAcceptedOffer) {
        return STEPS.USER_ACCEPTED;
    }

    if (
        props.responseGivenToLatestRevision === null ||
        props.responseGivenToLatestRevision === undefined ||
        props.withDrawnAcceptedOffer
    ) {
        return STEPS.UNTREATED;
    }

    if (ResponseHelper.isLoanOffer(props.responseGivenToLatestRevision)) {
        return STEPS.OFFER_GIVEN;
    }

    if (ResponseHelper.isDenial(props.responseGivenToLatestRevision)) {
        return STEPS.DENIAL_GIVEN;
    }

    return null;
};

const stepsArray = Object.values(STEPS);
const isDisabled = (activeStep, thisStep) => stepsArray.indexOf(activeStep) < stepsArray.indexOf(thisStep);

function StepIndicator(props) {
    const activeStep = getActiveStep(props);

    return (
        <Step.Group
            widths={5}
            fluid
            style={{
                boxShadow: '0px 2px 1px 1px rgba(78, 116, 144, 0.1)',
                borderRadius: '0.38571429rem',
                border: 'none',
            }}
            size="small"
            className={styles.StepGroup}
        >
            <Step
                active={activeStep === STEPS.UNTREATED}
                title="Untreated"
                description="The current revision needs a response"
            />
            <ResponseStep
                isDisabledFunction={isDisabled}
                response={props.responseGivenToLatestRevision}
                activeStep={activeStep}
            />
            <Step
                disabled={isDisabled(activeStep, STEPS.USER_ACCEPTED)}
                active={activeStep === STEPS.USER_ACCEPTED}
                title="User accepted"
                description="The user has accepted your offer"
            />
            <Step
                disabled={isDisabled(activeStep, STEPS.BOD_SENT)}
                active={activeStep === STEPS.BOD_SENT}
                title="Bill of debt sent"
                description="You have marked the bill of debt sent"
            />
            <Step
                disabled={isDisabled(activeStep, STEPS.LOAN_PAID)}
                active={activeStep === STEPS.LOAN_PAID}
                title="Loan paid out"
                description="The loan has been paid out"
            />
        </Step.Group>
    );
}

StepIndicator.propTypes = {
    billOfDebtSent: PropTypes.bool,
    loanPaidOut: PropTypes.bool,
    hasAcceptance: PropTypes.bool,
    responseGivenToLatestRevision: PropTypes.shape({}),
};
StepIndicator.defaultProps = {
    responseGivenToLatestRevision: null,
    billOfDebtSent: false,
    loanPaidOut: false,
    hasAcceptance: false,
};

export default StepIndicator;
